import { motion } from "framer-motion"
import AuditDateSelector from "../../../../Selectors/AuditDateSelector/AuditDateSelector"
import { useState } from "react"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddAssignmentStep from "../../../../models/addAssignmentStep";

function SelectLastAudit(props: any) {

    const step: AddAssignmentStep = props.step
    const [lastAudit, setLastAudit] = useState<string | null>()
    const skip = props.skip

    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation
    
    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const handleUpdateLastAudit = (audit: string | null) => {
        if (audit) {
            setLastAudit(audit)
        }
    }

    const handleUpdateParentLastAudit = (audit: string | null) => {
        if (audit) {
            props.lastAudit(lastAudit)
        } else {
            props.lastAudit(null)
        }
    }

    const skipAuditEntry = () => {
        if (skip) {
            return(
                <button
                    onClick={ () => {handleUpdateParentLastAudit(null)} }
                    className="EnterID-skip-audit-entry"
                > Skip 
                </button>
            )
        }
    }

    return(
        <>
            { (step.name === "selectLastAudit") && (
                <motion.div
                    variants={item}
                    initial="hidden"
                    animate="visible"
                    transition={{ velocity: 1 }}
                >
                    <div className="EnterID-container">
                        { step.number && (
                            <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        )}
                        <div className="EnterID-text"> 
                            Enter Last Audit Date:
                        </div>
                        <AuditDateSelector audit={handleUpdateLastAudit}/>
                    { lastAudit && (
                        <button 
                            className="EnterID-button"
                            onClick={ () => { handleUpdateParentLastAudit(lastAudit) } }
                            > 
                            <ArrowForwardIosIcon sx = {{  fontSize: "1.25em"  }}/>
                        </button>
                    )}
                    </div>
                    <div className="EnterID-container">
                        {skipAuditEntry()}
                    </div>
                </motion.div>
            )}
        </>
    )
}
export default SelectLastAudit
import { useEffect, useState,  useMemo } from "react";
import { useGetStandards } from "../../../../Components/Hooks/useGetStandards";
import Standard from "../../../models/standard.interface";
import "./StandardsList.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Requirements from "../Requirements/Requirements";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StandardAdditionalInformation from "../AdditionalStandardInformation/StandardsAdditionalInformation";
import StandardStatus from "../../../models/standard.status.interface";
import { AnimatePresence, motion } from "framer-motion";

function StandardsPage(props: any) {
    const roles = props.roles
    const rolesArray = useMemo(() => roles.split(" "), [roles]); // using useMemo here to prevent unnecessary re-renders
    console.log(rolesArray)
    const family = props.family
    const status: StandardStatus = props.status
    const { getStandards, standards, standardsErrorMsg, loading } = useGetStandards();
    const [selectedStandard, setSelectedStandard] = useState<String>()

    useEffect(() => {
        if (family !== "" && status.url) {
            getStandards(status.url, family, rolesArray);
        }
    }, [status, roles, family, getStandards, rolesArray])

    const variants = {
        open: { opacity: 1, height: "100%" },
        closed: { opacity: 0, height: "0px" },
    }

    const displayDetailsAndRequirements = (standard: Standard) => {

        return(
            <AnimatePresence>
                { standard.standard_number === selectedStandard && (
                    <motion.div 
                        layout
                        animate={ "open" }
                        transition={{ duration: 0.2 }}
                        exit={ "closed" }
                        variants={variants}
                        className="StandardsList-details-and-requirements-container"
                    >
                        <StandardAdditionalInformation standard={standard} />
                        <Requirements standardNumber={selectedStandard} roles={rolesArray} selectedRole={""}/>
                    </motion.div>
                )}
            </AnimatePresence>
        )
    }

    const selectStandard = (standard: Standard) => {
        if (standard.standard_number !== selectedStandard) {
            setSelectedStandard(standard.standard_number)
        } else {
            setSelectedStandard("")
        }
    }

    const arrowDirectionButton = (standard: Standard) => {
        if (standard.standard_number === selectedStandard) {
            return(
                <ExpandLessIcon sx = {{ color: "rgb(6, 26, 101)", fontSize: "calc(6px + 1vw)"}}/>
            )
        } else {
            return(
                <ExpandMoreIcon sx = {{ color: "rgb(6, 26, 101)", fontSize: "calc(6px + 1vw)", p: 0}}/> 
            )
        }
    }

    const standardsList = () => {
        if (loading) {
            return (
                <div>Searching for Applicable Standards ... </div> // consdier improving this in the future
            );
        } else if (standards.length >= 1) {
            return (
                <div className="StandardsList-container">
                    {standards.map((standard: Standard, index) => {
                        return(
                            <div className="StandardsList-displayAll">
                                <div 
                                    key={standard.standard_title + "background"} 
                                    className="StandardsList-standard-container" 
                                    style={{ 
                                        fontWeight: standard.standard_number === selectedStandard ? "bold" : "normal",
                                        }}>
                                    <div key={standard.standard_number.toString()} className="StandardsList-number-container">{standard.standard_number}</div>
                                        <div key={standard.standard_title.toString()} className="StandardsList-title-container">{standard.standard_title}</div>
                                        <button key={standard.standard_title + "button"} className="StandardsList-view-more-button" onClick={() => selectStandard(standard)}> 
                                            {arrowDirectionButton(standard)}
                                        </button>
                                </div>
                                {displayDetailsAndRequirements(standard)}
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div className="StandardsList-no-standards">
                    No Applicable Standards
                </div>
            )
        }
    }

    if (standardsErrorMsg) {
        return (
            <div>Error: {standardsErrorMsg}</div>
        );
    }

    return (
        <>
            {standardsList()}
        </>
    )

}
export default StandardsPage

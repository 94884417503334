import { useState } from "react";
import ApplicabilityStats from "../../models/applicabilityStats";
import Standard from "../../models/standard.interface";

export default (): [(lastAudit: string, nextAudit: string, roles: string[], accessToken: string) => void, Standard[] | undefined, string]  => {
 
    const [deltaStandards, setDeltaStandards] = useState<Standard[] | undefined>();
    const [deltaStandardsErrorMsg, setDeltaStandardsErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getDeltaStandards = async (lastAudit: string, nextAudit: string, roles: string[], accessToken: string) => {
        const data = await fetch(`${apiServer}/private/dashboard/getDeltaStandards/${lastAudit}/${nextAudit}/${roles}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        
        const jsonData = await data.json() as Standard[] | undefined;
        console.log("getting delta standards data")
        if (jsonData !== undefined && jsonData.length > 0) setDeltaStandards(jsonData)
        else {setDeltaStandards(undefined); setDeltaStandardsErrorMsg("No Delta Standards Found");}
    }
    return [getDeltaStandards, deltaStandards, deltaStandardsErrorMsg]
}
import { useEffect, useState } from "react";
import RolesHook from "../../../../API/RegistryController/RolesHook";
import MultipleRolesSelector from "../../../../Selectors/MultipleRolesSelector/MultipleRolesSelector";
import { motion } from "framer-motion";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddAssignmentStep from "../../../../models/addAssignmentStep";


function SelectRoles(props: any) {

    const ncrid = props.ncrid
    const step: AddAssignmentStep = props.step
    const idResult = props.idResult
    const [getRoles, roles, rolesErrorMsg] = RolesHook();
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])

    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    useEffect(() => {
        if (ncrid) {
            getRoles(ncrid)
        }
    }, [ncrid])

    const handleSelectedRoles = () => {
        if (selectedRoles.length > 0) {
            props.selectedRoles(selectedRoles)
        }
    }

    const updateSelectedRoles = (selectedRoles: string[]) => {
        setSelectedRoles(selectedRoles)
    }

        return (
            <>
                { (step.name === "selectRoles") && (
                    <motion.div 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        transition={{ velocity: 1 }}
                        className="EnterID-container"
                    >
                        { step.number && (
                            <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        )}  
                        <div className="EnterID-text"> 
                            Select Roles:
                        </div>
                        <MultipleRolesSelector roles={roles} selectedRoles={updateSelectedRoles} result={idResult}/>
                        { selectedRoles.length > 0 && (
                            <button 
                                className="EnterID-button"
                                onClick={ handleSelectedRoles }
                                > 
                                <ArrowForwardIosIcon sx = {{  fontSize: "1.25em"  }}/>
                            </button>
                        )}
                    </motion.div>
                )}
            </>
        )
}
export default SelectRoles
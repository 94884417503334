import { useState } from 'react'
import StandardStatus from '../../models/standard.status.interface'
import StandardStatusSelector from '../../Selectors/StandardStatusSelector/StandardStatusSelector'
import StandardsList from './StandardsList/StandardsList'
import "./LandingPage.css"
import FamilySelector from '../../Selectors/FamilySelector/FamilySelector'
import RoleSelector from '../../Selectors/RoleSelector/RoleSelector'

function LandingPage(props: any) {

    const ncrid = props.ncrid
    const [family, setFamily] = useState("")
    const [status, setStatus] = useState<StandardStatus>({ name: "Mandatory Subject to Enforcement", url: "Mandatory%20Subject%20to%20Enforcement" })
    const roles = ["BA", "DP", "GO", "GOP", "PAPC", "RC", "RP", "RSG", "FRSG", "RRSG", "TO", "TOP", "TP", "TSP"]
    const [selectedRole, setSelectedRole] = useState("")

    const selectors = () => {
        return(
        <div className="LandingPage-selectors">
            <div className="LandingPage-selector">
                <RoleSelector roles={roles} selectedRole={updateSelectedRole} />
            </div>
            <div>
                <StandardStatusSelector status={updateStatus} autoFillStatus={true}/>
            </div>
            <div className="LandingPage-selector">
                <FamilySelector family={updateFamily}/>
            </div>
        </div>
        )
    }

    const queryCard = () => {
        return(
            <div className="LandingPage-query-card">
                {selectors()}
            </div>
        )
    }

    const updateSelectedRole = (selectedRole: string) => {
        setSelectedRole(selectedRole)
    }
    
    const updateStatus = (status: StandardStatus) => {
        setStatus( {name: status.name, url: status.url})
    }

    const updateFamily = (family: string) => {
        setFamily(family)
    }

    const returnStandardsList = () => {
        if (family === "" || selectedRole === "") {
            return(
                <></>
            )
        } else {
            return(
                <StandardsList family={family} roles={selectedRole} status={status}/>
            )
        }
    }

        return(
            <div className="LandingPage">
                <div className="LandingPage-header">
                    Standards Filter
                </div>
                <div className="LandingPage-select-family-hint"> 
                    Select a Role, Status, and Family to view Applicable Standards.
                </div>
            {queryCard()}
            {returnStandardsList()}
            </div>
        )
}
export default LandingPage
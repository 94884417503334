import { useState, useCallback } from "react";
import Standard from "../models/standard.interface";

export const useGetStandards = () => {
    const [standards, setStandards] = useState<Standard[]>([]);
    const [standardsErrorMsg, setStandardsErrorMsg] = useState("");
    const [loading, setLoading] = useState<boolean>(false); // Add loading state
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getStandards = useCallback(async (status: string, family: string, roles: string[]) => {
        try {
            setLoading(true); // Start loading before fetching
            const response = await fetch(`${apiServer}/standards/${status}/${family}/${roles.join(",")}`, {
                method: "GET"
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json() as Standard[];
            console.log("getting standards")
            if (jsonData.length > 0) {
                setStandards(jsonData);
                setStandardsErrorMsg(""); // Clear any previous error messages
            } else {
                setStandards([]);
                setStandardsErrorMsg(`No ${status} Standards found for ${family} family.`);
            }
        } catch (e) {
            if (e instanceof Error) { // Check if e is an instance of Error
                console.error('An error occurred while fetching standards:', e);
                setStandardsErrorMsg(`An error occurred: ${e.message}`);
            }
        } finally {
            setLoading(false); // Stop loading after fetching
        }
    }, [apiServer]); // apiServer as dependency

    return { getStandards, standards, standardsErrorMsg, loading };
};

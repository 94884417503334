import { useEffect, useState } from "react"
import GetStandardsWithinAuditDateRange from "../../../API/DashboardController/GetStandardsWithinAuditDateRange"
import Standard from "../../../models/standard.interface"
import UserAssignment from "../../../models/userAssignments.interface"
import "./AuditRangeStandards.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Requirements from "../../LandingPage/Requirements/Requirements"
import StandardAdditionalInformation from "../../LandingPage/AdditionalStandardInformation/StandardsAdditionalInformation"
import { useNavigate } from "react-router"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AnimatePresence, motion } from "framer-motion"
import FilterAuditStandardsBy from "./FilterBy/FilterAuditStandardsBy"

function AuditRangeStandards(props: any) {

    const [getStandardsWithinAuditRange, standardsWithinAuditRange, standardsWithinAuditRangeErrorMsg] = GetStandardsWithinAuditDateRange()
    const [viewStandards, setViewStandards] = useState(false)
    const [selectedStandard, setSelectedStandard] = useState("")
    const assignment: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const rolesArray = assignment.roles.replace(/[""]+/g, "").split(",")
    const  navigate  = useNavigate()
    const [family, setFamily] = useState("")
    const [status, setStatus] = useState("")
    const [selectedRole, setSelectedRole] = useState("")
    const [violationSeverity, setViolationSeverity] = useState("")

    const handleViewStandards = () => {
        setViewStandards(!viewStandards)
        setSelectedStandard("")
    }

    const updateFamily = (family: string) => {
        setFamily(family)
    }

    const updateStatus = (status: string) => {
        setStatus(status)
    }

    const updateSelectedRole = (role: string)  => {
        setSelectedRole(role)
    }
    const updateViolationSeverity = (violation: string) => {
        setViolationSeverity(violation)
    }

    useEffect(() => {
        setViewStandards(false)
        setFamily("")
        setStatus("")
        setSelectedRole("")
    }, [assignment])

    useEffect(() => {
        if (assignment.last_audit && assignment.next_audit && viewStandards) {
            getStandardsWithinAuditRange(assignment.last_audit, assignment.next_audit, rolesArray, accessToken, family, status, selectedRole, violationSeverity);
        }
    }, [family, status, selectedRole, viewStandards, violationSeverity])

    const standardArrowDirectionButton = (standard: Standard) => {
        if (standard.standard_number === selectedStandard) {
            return(
                <ExpandLessIcon 
                    onClick = {() => {setSelectedStandard("")}}
                    className="AuditRange-button-container"
                    sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em"}}
                />
            )
        } else {
            return(
                <ExpandMoreIcon 
                    onClick = {() => {setSelectedStandard(standard.standard_number)}}
                    className="AuditRange-button-container"
                    sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em", p: 0}}
                /> 
            )
        }
    }

    const variants = {
        open: { opacity: 1, height: "100%" },
        closed: { opacity: 0, height: "0px" },
    }

    return(
        <div className="AuditRange">
            <div className="AuditRange-buttons-column">
                { assignment.last_audit && assignment.next_audit && (
                    <button
                        className="AuditRange-button-row"
                        onClick={handleViewStandards}
                    >
                        <div className="AuditRange-button-text"> View the Applicable Standards for Your Upcoming Audit </div>
                        { viewStandards && (
                            <ExpandLessIcon 
                                className="AuditRange-button-container"
                                sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em", p: "2px"}}
                            />
                        )}
                        { !viewStandards && (
                            <ExpandMoreIcon 
                                className="AuditRange-button-container"
                                sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em", p: "2px"}}
                            /> 
                        )}
                    </button>
                )}
                { !assignment.last_audit && !assignment.next_audit && ( 
                    <button
                        className="AuditRange-button-row"
                        onClick={() => {navigate("/profile/user-assignments")}}
                    >
                        <div className="AuditRange-button-text"> Update audit dates to view the standards applicable to your upcoming audit </div>
                        <KeyboardArrowRightIcon className="AuditRange-button-container"/>
                    </button>
                )}
            </div>
            { viewStandards &&  assignment.last_audit && assignment.next_audit && (
                <>
                    <FilterAuditStandardsBy assignment={assignment} family={updateFamily} status={updateStatus} role={updateSelectedRole} violationSeverity={updateViolationSeverity}/>
                    { !standardsWithinAuditRange && (
                        <div className="AuditRange-button-row" style= {{ padding: "5px" }} > No Standards Found </div>
                    )}
                    <AnimatePresence>
                        { standardsWithinAuditRange && (
                            <motion.div 
                                layout
                                animate={ "open" }
                                transition={{ duration: 0.2 }}
                                exit={ "closed" }
                                variants={variants}
                                className="AuditRange-standards-list"
                            >
                                {standardsWithinAuditRange.map((standard: Standard) => {
                                    return(
                                        <>
                                            <div 
                                                className="AuditRange-standard-table"
                                                style={{ 
                                                    fontWeight: standard.standard_number === selectedStandard ? "bold" : "normal",
                                                }}
                                            > 
                                                <div> {standard.standard_number} </div>
                                                <div> {standard.standard_title} </div>
                                                <div> {standard.status} </div>
                                                {standardArrowDirectionButton(standard)}
                                            </div>
                                            <AnimatePresence>
                                                { standard.standard_number === selectedStandard && (
                                                    <motion.div 
                                                        layout
                                                        animate={ "open" }
                                                        transition={{ duration: 0.2 }}
                                                        exit={ "closed" }
                                                        variants={variants}
                                                        className="AuditRange-additional-info-and-requirements"
                                                    >
                                                        <StandardAdditionalInformation standard={standard} />
                                                        <Requirements standardNumber={selectedStandard} roles={rolesArray} selectedRole={selectedRole}/>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </>
                                    )
                                })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
            )}
        </div>
    )
}
export default AuditRangeStandards
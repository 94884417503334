import { useEffect, useState } from "react";
import RequirementsHook from "../../../API/StandardsController/RequirementsHook";
import Requirement from "../../../models/requirement.interface";
import PartsList from "../PartsList/PartsList";
import "./Requirements.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AnimatePresence, motion } from "framer-motion";

function Requirements(props: any) {

    const [getRequirements, requirements, requirementsErrorMsg] = RequirementsHook();
    const standardNumber = props.standardNumber
    const roles = props.roles
    const selectedRole = props.selectedRole
    const [selectedRequirement, setSelectedRequirement] = useState("")

    useEffect(() => {
        if (selectedRole === "") {
            getRequirements(standardNumber, roles);
        } else {
            getRequirements(standardNumber, selectedRole.split(" "));
        }
    } ,[standardNumber, selectedRole])

    const variants = {
        open: { opacity: 1, height: "100%" },
        closed: { opacity: 0, height: "0px" },
    }

    const displayRequirementInformation = (requirement: Requirement) => {
        return(
            <AnimatePresence>
                { selectedRequirement === requirement.requirement_number && (
                    <motion.div 
                        layout
                        animate={ "open" }
                        transition={{ duration: 0.2 }}
                        exit={ "closed" }
                        variants={variants}
                        className="Requirements-container"
                    >
                        <div className="Requirements-row-container">
                            <div className="Requirements-header">
                                {requirement.requirement_number}
                            </div>
                            <div className="Requirements-language">
                                {requirement.requirement_language}
                            </div>
                        </div>
                        <PartsList standardNumber={standardNumber} requirementNumber={requirement.requirement_number} roles={roles}/>
                        <div className="Requirements-row-container">
                            <div className="Requirements-header">
                                {requirement.requirement_number?.replace("R", "M")}
                            </div>
                            <div className="Requirements-language">
                                {requirement.requirement_measure?.replace(requirement.requirement_number?.replace("R", "M"), "").replace("&lt;p&gt;", "").replace(".&lt;/p&gt;", "").replace("&lt;br/&gt;", " ").replace("&lt;br/&gt;", " ").replace(/[]+/g, "")}
                            </div>
                        </div>
                        <div className="Requirements-row-container">
                            <div className="Requirements-header">
                                Violation Risk Severity Levels:
                            </div>
                        </div>
                        <div className="Requirements-violations-names">
                            <div className="Requirements-violation-level-name">Lower</div>
                            <div className="Requirements-violation-level-name">Moderate</div>
                            <div className="Requirements-violation-level-name">High</div>
                            <div className="Requirements-violation-level-name">Severe</div>
                        </div>
                        <div className="Requirements-violations">
                            <div className="Requirements-violation-level">
                            {requirement.violation_severity_level_lower}
                            </div>
                            <div className="Requirements-violation-level">
                            {requirement.violation_severity_level_moderate}
                            </div>
                            <div className="Requirements-violation-level">
                            {requirement.violation_severity_level_high}
                            </div>
                            <div className="Requirements-violation-level">
                            {requirement.violation_severity_level_severe}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        )
    }

    const handleSelectRequirement = (requirement: Requirement) => {
        if (selectedRequirement === requirement.requirement_number) {
            setSelectedRequirement("")
        } else {
            setSelectedRequirement(requirement.requirement_number)
        }
    }

    const arrowDirectionButton = (requirement: Requirement) => {
        if (requirement.requirement_number === selectedRequirement) {
            return(
                <ExpandLessIcon sx = {{ color: "rgb(6, 26, 101)", fontSize: "calc(6px + 1vw)"}}/>
            )
        } else {
            return(
                <ExpandMoreIcon sx = {{  color: "rgb(6, 26, 101)", fontSize: "calc(6px + 1vw)"}}/> 
            )
        }
    }

    return(
        <div className="Requirements">
            <div className="Requirements-table-header">
                <div> Requirements </div>
                <div> Violation Risk Factor </div>
                <div>  </div>
            </div>
            {requirements.map((requirement: Requirement) => {
            let violationColor = false

            const checkViolationRiskFactor = () => {
                if (requirement.violation_risk_factor === "High" || requirement.violation_risk_factor === "Severe") {
                    violationColor = true
                }
            }

            checkViolationRiskFactor();

                return(
                    <>
                        <div className="Requirements-table"> 
                            <div> {requirement.requirement_number.replace("R", "Requirement ").replace(".", "")} </div>
                            <div style={{ color: violationColor ? "red" : "black " }}> {requirement.violation_risk_factor} </div>
                            <button
                            className="Requirements-view-more-button"
                            onClick={() => {handleSelectRequirement(requirement)}}
                            > {arrowDirectionButton(requirement)} </button>
                        </div>
                        {displayRequirementInformation(requirement)}
                    </>
                )
            })}
        </div>
    )
}
export default Requirements
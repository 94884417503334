import { useState } from "react";
import ApplicabilityStats from "../../models/applicabilityStats";

type ApplicabilityStatsTuple = [
  (
    lastAudit: string,
    nextAudit: string,
    roles: string[],
    accessToken: string
  ) => Promise<void>,
  ApplicabilityStats[] | undefined,
  string
];

const useApplicabilityStats = (): ApplicabilityStatsTuple => {
  const [applicabilityStats, setApplicabilityStats] = useState<
    ApplicabilityStats[] | undefined
  >();
  const [applicabilityStatsErrorMsg, setApplicabilityStatsErrorMsg] = useState<
    string
  >("");

  const getApplicabilityStats = async (
    lastAudit: string,
    nextAudit: string,
    roles: string[],
    accessToken: string
  ) => {
    try {
      const apiServer = process.env.REACT_APP_API_SERVER_URL;
      const response = await fetch(`${apiServer}/private/dashboard/getApplicabilityTableData/${lastAudit}/${nextAudit}/${roles}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const jsonData = await response.json() as ApplicabilityStats[] | undefined;

      if (jsonData !== undefined && jsonData.length > 0) {
        setApplicabilityStats(jsonData);
        setApplicabilityStatsErrorMsg("");
      } else {
        setApplicabilityStats(undefined);
        setApplicabilityStatsErrorMsg("No Applicability Stats Found");
      }
    } catch (error) {
      // Handle and log the error appropriately
      console.error("Error fetching applicability stats:", error);
      setApplicabilityStats(undefined);
      setApplicabilityStatsErrorMsg("Error fetching applicability stats");
    }
  };

  return [getApplicabilityStats, applicabilityStats, applicabilityStatsErrorMsg];
};

export default useApplicabilityStats;

import AddAssignmentStep from "../../../../models/addAssignmentStep"

function ReviewAssignmentEntry(props: any) {

    const step: AddAssignmentStep = props.step
    const entity = props.entity
    const region = props.region
    const ncrid = props.ncrid 
    const roles = props.roles
    const lastAudit = props.lastAudit
    const nextAudit = props.nextAudit
    const auditCadence = props.auditCadence
    
    return(
        <>
            <div
                className="Enter-ID-assignment-review"
            >
                <div className="EnterID-container"> 
                    <div className="EnterID-text"> Entity: </div>
                    <div> {entity} </div>
                </div>
                <div className="EnterID-container"> 
                    <div className="EnterID-text"> Region: </div>
                    <div> {region} </div>
                </div>
                <div className="EnterID-container"> 
                    <div className="EnterID-text"> NCRID: </div>
                    <div> {ncrid} </div>
                </div>
                <div className="EnterID-container"> 
                    <div className="EnterID-text">  Roles: </div>
                    <div> {roles.join(", ")} </div>
                </div>
                { lastAudit && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Last Audit: </div>
                        <div> {lastAudit} </div>
                    </div>
                )}
                { !lastAudit && step.name !== "reviewAssignmentEntry" && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Last Audit: </div>
                        <div> {lastAudit} </div>
                    </div>
                )}
                { nextAudit && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Next Audit: </div>
                        <div> {nextAudit} </div>
                    </div>
                )}
                { !nextAudit && step.name !== "reviewAssignmentEntry" && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Next Audit: </div>
                        <div> {nextAudit} </div>
                    </div>
                )}
                { auditCadence && step.name === "reviewAssignmentEntry" && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Audit Cadence: </div>
                        <div> {auditCadence} year(s) </div>
                    </div>
                )}
                 { !auditCadence && step.name !== "reviewAssignmentEntry" && (
                    <div className="EnterID-container"> 
                        <div className="EnterID-text"> Audit Cadence: </div>
                        <div> {auditCadence} year(s) </div>
                    </div>
                )}
            </div>
        </>
    )
}
export default ReviewAssignmentEntry

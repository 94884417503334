import EditIcon from '@mui/icons-material/Edit';
import "./EditUserAssignments.css"
import UserAssignment from '../../../../models/userAssignments.interface';
import SelectRoles from '../../AddUserAssignment/Steps/SelectRoles';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UpdateUserAssignmentRoles from '../../../../API/UserAssignmentsController/UpdateUserAssignmentRoles';
import { AnimatePresence, motion } from 'framer-motion';
import AddAssignmentStep from '../../../../models/addAssignmentStep';

function EditUserAssignmentRoles(props: any) {

    const step: AddAssignmentStep = { name: "selectRoles", number: null }
    const idResult: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const ncrid = idResult.ncrid
    const [ selectedRoles, setSelectedRoles ] = useState<string[]>([])
    const [ editRolesPopUp, setEditRolesPopUp ] = useState(false)
    const [updateUserAssignmentRoles] = UpdateUserAssignmentRoles()

    const [initAnimation, setInitAnimation] = useState(  { opacity: 0, x: "0%" } )
    const [finalAnimation, setFinalAnimation] = useState( { opacity: 1, x: "0%" } )

    const assignment: UserAssignment = {
        id: idResult.id,
        user_id: idResult.user_id,
        entity: idResult.entity,
        region: idResult.region,
        ncrid: idResult.ncrid,
        roles: JSON.stringify(selectedRoles),
        last_audit: idResult.last_audit,
        next_audit: idResult.next_audit,
        audit_cadence: idResult.audit_cadence
    }

    const handleUpdateSelectedRoles = (selectedRoles: string[]) => {
        setSelectedRoles(selectedRoles);
    }

    useEffect(() => {
        if (selectedRoles && editRolesPopUp) {
            updateUserAssignmentRoles(assignment, accessToken)
            setEditRolesPopUp(false)
            props.refresh(true)
        }
    }, [selectedRoles])

    const handleEditRolesPopup = () => {

        return(
            <AnimatePresence>
                { editRolesPopUp && (
                    <motion.div 
                        className="EditUserAssignment-pop-up"
                        initial={{ opacity: 0, scale: 0.75, }}
                        animate={{ opacity: 1, scale: 1,
                            transition: {
                                ease: "easeOut",
                                duration: 0.15,
                            }, }}
                        exit={{
                            opacity: 0,
                            scale: 0.75,
                            transition: {
                                ease: "easeIn",
                                duration: 0.15,
                            },
                        }}  
                    >
                        <SelectRoles step={step}  idResult={idResult} ncrid={ncrid} selectedRoles={handleUpdateSelectedRoles} initAnimation={initAnimation} finalAnimation={finalAnimation} />
                        <CloseIcon 
                            className="EditUserAssignment-pop-up-close-icon" 
                            onClick = {() => { setEditRolesPopUp(!editRolesPopUp)} }
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        )
    }

    return(
        <>
            <EditIcon 
                className="EditUserAssignments-button-edit-container" 
                onClick = { () => { setEditRolesPopUp(!editRolesPopUp) } }
            />
            {handleEditRolesPopup()}
       </>

    )
}
export default EditUserAssignmentRoles
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
//import { useHistory } from "react-router-dom";
import CreateNewUser from "../API/UsersController/CreateNewUser";
import GetUserInfo from "../API/UsersController/GetUserInfo";
import User from "../models/user.interface";

const UserCheckHook = (): [User | undefined, string] => {

    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [getUserInfo, userInfo, userInfoErrorMsg] = GetUserInfo();
    const [accessToken, setAccessToken] = useState("");
    const [creatingUser, setCreatingUser] = useState(false);  // Added new state
    const [createNewUser] = CreateNewUser();
    //const history = useHistory();  // Added useHistory

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            })();
        }
    }, [user, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!userInfo && accessToken) {
            getUserInfo(user?.sub, accessToken)
        }
    }, [accessToken, getUserInfo, user, userInfo])

    useEffect(() => {
        if (userInfo === undefined && accessToken && !creatingUser) {  // Added check for !creatingUser
            const current = new Date();
            const myData = {
                id: `${user?.sub}`,
                user_name: `${user?.name}`,
                email: `${user?.email}`,
                activation_date: `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`
            }
            setCreatingUser(true);
            createNewUser(myData, accessToken).then(() => {
                setCreatingUser(false);
                //history.go(0);  // Using history.go(0) instead of window.location.reload()
            })
        }
    }, [userInfoErrorMsg, userInfo, accessToken, createNewUser, user, creatingUser, ])  
    return [userInfo, accessToken]
}

export default UserCheckHook;

import { useEffect, useState } from "react"
import "./SummaryStats.css"
import "./DeltaStandards.css"
import UserAssignment from "../../../models/userAssignments.interface"
import GetDeltaStandards from "../../../API/DashboardController/GetDeltaStandards"
import Standard from "../../../models/standard.interface"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function DeltaStandards(props: any) {

    const assignment: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const selectedRole = props.selectedRole
    const rolesArray = assignment.roles.replace(/[""]+/g, "").split(",")
    const [getDeltaStandards, deltaStandards, deltaStandardsErrorMsg] = GetDeltaStandards()
    const [viewStandards, setViewStandards] = useState(false)

    useEffect(() => {
        if (assignment.last_audit && assignment.next_audit && selectedRole) {
            getDeltaStandards(assignment.last_audit, assignment.next_audit, selectedRole.split(","), accessToken)
        } else if (assignment.last_audit && assignment.next_audit) {
            getDeltaStandards(assignment.last_audit, assignment.next_audit, rolesArray, accessToken)
        }
    } , [assignment.id, props.selectedRole])

    const handleViewStandards = () => {
        setViewStandards(!viewStandards)
    }

    return(
        <>
            <div className="SummaryStats-row-container"> 
                <div className="SummaryStats-header-container"> 
                    <div className="DeltaStandards-header">
                        Delta Standards: 
                    </div>
                    <div className="DeltaStandards-length">
                        { deltaStandards && deltaStandards.length}
                    </div>
                    <button
                        className="DeltaStandards-button-row "
                        onClick={handleViewStandards}
                    >
                        { viewStandards && (
                            <ExpandLessIcon 
                                className="AuditRange-button-container"
                                sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em", p: "2px"}}
                                onClick={() => handleViewStandards}
                            />
                        )}
                        { !viewStandards && (
                            <ExpandMoreIcon 
                                className="AuditRange-button-container"
                                sx = {{ color: "rgb(6, 26, 101)", fontSize: "1.65em", p: "2px"}}
                                onClick={() => handleViewStandards}
                            /> 
                        )}
                    </button>
                </div>
            </div>
            { viewStandards && (
                <div className="DeltaStandards">
                    { deltaStandards?.map((deltaStandard: Standard) => 
                    <div className="DeltaStandards-table">
                        <div className="DeltaStandards-number">
                            {deltaStandard.standard_number}
                        </div>
                        <div className="DeltaStandards-notes">
                        {deltaStandard.public_notes}
                        </div>
                    </div>
                    )}
                </div>
            )}
        </>
    )
}
export default DeltaStandards
import LoginButton from "../../UserActions/Login/Login"
import LogoutButton from "../../UserActions/Logout/Logout"
import { useAuth0 } from "@auth0/auth0-react";
import "./Header.css"
import { Link, useLocation } from "react-router-dom";
import { SignupButton } from "../../UserActions/SignUp/SignUp";
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';

function Header() {

    const { isAuthenticated, user } = useAuth0();
    const location = useLocation();

    const logInAndOut = () => {
        return(
            <div className="header-nav-container-user">
            { isAuthenticated && (
                <>
                    <Link className="header-nav-link" to="/profile"> 
                        { user?.picture && (
                            <img className="header-user-picture" src={user?.picture} referrerPolicy="no-referrer"/>
                        )}
                        { !user?.picture && (
                            <PersonIcon sx = {{ color: "white" }} className="header-user-picture"/>
                        )}
                        <div className="header-user-settings"> <SettingsIcon /> </div>
                    </Link>
                    <LogoutButton />
                </>
            )}
            { !isAuthenticated && (
                <>
                    <LoginButton />
                    <SignupButton />
                </>
            )}
            </div>
        );
    };

    console.log(user?.picture)

    const pageNav = () => {
        return(
            <div className="header-nav-container">
                <Link 
                    className="header-nav-link" 
                    to="/"
                    style = {{ color: (location.pathname === "/") ? "white" : "lightgrey"}}
                > Standards Filter </Link>
                { isAuthenticated && (
                    <Link 
                        className="header-nav-link" 
                        to="/dashboard"
                        style = {{ color: (location.pathname === "/dashboard") ? "white" : "lightgrey"}}
                    > Dashboard </Link>
                )}
            </div>
        )
    }

    return(
        <div className="Header"> 
            <div className="header-logo"> 
                PowerCompli 
            </div>
            {pageNav()}
           {logInAndOut()}
        </div>
    )
}
export default Header
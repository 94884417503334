import CircularProgress from '@mui/material/CircularProgress';
import "./PageLoader.css"

function PageLoader() {
    return(
        <div className='PageLoader'> 
            <CircularProgress sx={{ color: "rgb(6, 26, 101)" }} size={100}/>
        </div>
    )
}
export default PageLoader
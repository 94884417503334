import UserAssignment from "../models/userAssignments.interface";

export const useCreateUserAssignment = (): [(assignmentData: UserAssignment, accessToken: string) => Promise<UserAssignment | null>] => {
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const createNewUserAssignment = async (assignmentData: UserAssignment, accessToken: string) => {
        try {
            const response = await fetch(`${apiServer}/private/users/createUserAssignment`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(assignmentData).replace("[", "").replace("]", "")
            });

            if (response.ok) {
                console.log("Creating New User Assignment");
                const result = await response.json();
                console.log(result);
                return result as UserAssignment;
            } else {
                console.error("Error creating user assignment:", response.status);
            }
        } catch (error) {
            console.error("An error occurred while creating user assignment:", error);
        }
        return null;
    };

    return [createNewUserAssignment];
};

import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Footer from "./Components/Nav/Footer/Footer";
import Header from "./Components/Nav/Header/Header";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import LandingPage from "./Components/Pages/LandingPage/LandingPage";
import PageLoader from "./Components/Pages/PageLoader/PageLoader";
import Profile from "./Components/Pages/ProfilePage/Profile";
import UserAssignments from "./Components/Pages/ProfilePage/UserAssignments/UserAssignments";
import UserInfo from "./Components/Pages/ProfilePage/UserInfo/UserInfo";
import UserCheckHook from "./Components/Hooks/UserCheckHook";

function App() {

  const [userInfo, accessToken] = UserCheckHook();

  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path ="/" element={<LandingPage />} />
        <Route path ="/profile/*" element={<Profile userInfo={userInfo} accessToken ={accessToken}/>} >
          <Route index element={<UserInfo userInfo={userInfo} accessToken ={accessToken}/>} />
          <Route path="user-assignments" element={userInfo ? <UserAssignments userInfo={userInfo} accessToken ={accessToken}/> : null} />
        </Route>
        <Route path ="/dashboard" element={userInfo ? <Dashboard userInfo={userInfo} accessToken ={accessToken}/>:null} />
      </Routes>
      <Footer />
    </div>
  );

}
export default App;


import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import GetRegistryByEntity from "../../../../API/RegistryController/GetRegistryByEntity";
import Registry from "../../../../models/registry.interface";
import TextField from "../TextField";
import AddAssignmentStep from "../../../../models/addAssignmentStep";

function SearchEntity(props: any) {

    const [input, setInput] = useState("")
    const [getResult, result, errorMsg] = GetRegistryByEntity()
    const step: AddAssignmentStep = props.step
    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const spring = {
        type: "spring",
        damping: 10,
        stiffness: 100
    }

    useEffect(() => {
        if (input !== "") {
            getResult(input);
        }
    }, [input])

    const handleSelectedEntityAndRegion = (entity: string, region: string, ncrid: string) => {
        props.company({ entity: entity, region: region, ncrid: ncrid })
    }

    const selectEntityAndRegion = () => {
        if (result.length > 0) {
            return(
                <div className="EnterID-container-and-response">
                    {result.map((register: Registry) => {
                        return(
                            <button 
                                key={register.entity + register.rcea} 
                                className="item-container"
                                onClick = {() => { handleSelectedEntityAndRegion( register.entity, register.rcea, register.ncrid ); setInput(register.entity) }}
                            > {register.entity} {register.rcea}
                            </button>
                        )
                    })}
                </div>
            )
        } else if (errorMsg.length > 0 && input.length > 0){
            return(
                <div key={"ncrid error"}className="error-container"> {errorMsg} </div>
            )
        }
    }

    return(
        <>
            { (step.name === "searchEntity") && (
                <motion.div 
                    variants={item}
                    initial="hidden"
                    animate="visible"
                    transition={{ velocity: 1 }}
                    className="EnterID-container-and-response"
                >
                    <div className="EnterID-container"> 
                        <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        <div className="EnterID-text"> 
                            Enter Entity:
                        </div>
                        <TextField 
                            value={input}
                            onChange={setInput}
                        />
                    </div>
                    {selectEntityAndRegion()}
                </motion.div>
            )}
        </>
    )
}
export default SearchEntity
import { motion } from "framer-motion"
import AuditDateSelector from "../../../../Selectors/AuditDateSelector/AuditDateSelector"
import { useState } from "react"
import AuditDateCadenceSelector from "../../../../Selectors/AuditDateCadenceSelector/AuditDateCadenceSelector"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddAssignmentStep from "../../../../models/addAssignmentStep";

function SelectNextAudit(props: any) {
    
    const step: AddAssignmentStep = props.step
    const error = props.error
    const [nextAudit, setNextAudit] = useState<string | null>()
    const [auditCadence, setAuditCadence] = useState<number | null>()

    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const handleUpdateNextAudit = (audit: string | null) => {
        setNextAudit(audit)
    }

    const handleUpdateAuditCadence = (cadence: number) => {
        setAuditCadence(cadence)
    }

    const handleUpdateParentNextAudit = () => {
        if (nextAudit) {
            props.nextAudit(nextAudit)
        }
    }

    const handleUpdateParentAuditCadence = () => {
        if (auditCadence) {
            props.auditCadence(auditCadence)
        }
    }

    return(
        <>
            { (step.name === "selectNextAudit") && (
                <motion.div
                    variants={item}
                    initial="hidden"
                    animate="visible"
                    transition={{ velocity: 1 }}
                >
                    <div className="EnterID-container">
                        { step.number && (
                            <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        )}
                        <div className="EnterID-text"> 
                            Enter Upcoming Audit Date:
                        </div>
                        <AuditDateSelector error={error} audit={handleUpdateNextAudit}/>
                        { nextAudit && (
                            <button 
                                className="EnterID-button"
                                onClick={ handleUpdateParentNextAudit }
                                > 
                                <ArrowForwardIosIcon sx = {{  fontSize: "1.25em"  }}/>
                            </button>
                        )}
                    </div>
                    <div className="EnterID-error-text" > {error} </div>
                    <div className="EnterID-container">
                        <div className="EnterID-text"> 
                            or
                        </div>
                    </div>
                    <div className="EnterID-container">
                        <div className="EnterID-text"> 
                            Enter Audit Cadence:
                        </div>
                        <AuditDateCadenceSelector cadence={handleUpdateAuditCadence}/>
                        { auditCadence && (
                            <button 
                                className="EnterID-button"
                                onClick={ handleUpdateParentAuditCadence }
                                > 
                                <ArrowForwardIosIcon sx = {{  fontSize: "1.25em"  }}/>
                            </button>
                        )}
                    </div>
                </motion.div>
            )}
        </>
    )
}
export default SelectNextAudit
import { useEffect } from "react";
import GetParts from "../../../API/StandardsController/GetParts";
import Part from "../../../models/part.interface";
import "./PartsList.css"

function PartsList(props: any) {

    const standardNumber = props.standardNumber
    const requirementNumber = props.requirementNumber
    const roles = props.roles

    const [getParts, parts, partsErrorMsg] = GetParts();

    useEffect(() => {
        getParts(standardNumber, requirementNumber.replace(".", "%2E"), roles)
    }, [standardNumber, requirementNumber])

    const partDisplay = (part: Part) => {
        const partNumber = part.part_displaystring.replace(standardNumber, "").replace("R", "")
        return(
            <div className="Part-header">
                {partNumber}
                <div className="Part-language-container">
                    {part.part_language}
                </div>
            </div>
        )
    }

    if (parts.length > 0) {
        return(
            <div className="PartsList-container">
                {parts.map((part: Part) => {
                    if (!part.part_displaystring.includes("(Ontario)")) {
                    return(
                        <>
                            {partDisplay(part)}
                        </>
                    )
                    }
                })}
            </div>
        )
    } else {
        return(
            <></>
        )
    }
}
export default PartsList
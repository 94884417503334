import { Checkbox, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import UserAssignment from "../../models/userAssignments.interface"

function ViolationSeveritySelector(props: any) {

    const [violationSeverity, setViolationSeverity] = useState("")
    const violationSeverityArray = ["Low", "Medium", "High"]
    const assignment: UserAssignment = props.assignment

    const handleRoleChange = (event: SelectChangeEvent) => {
        setViolationSeverity(event.target.value)
    }

    useEffect(() => {
        props.violationSeverity(violationSeverity)
    }, [violationSeverity])

    useEffect(() => {
        setViolationSeverity("")
    }, [assignment])

    return(
        <div className="RoleSelector"> 
            <FormControl sx={{ m: 0 }}>
                <Select
                sx= {{ fontSize: "1em", borderRadius: "50px", padding: "5px"}}
                value={violationSeverity}
                onChange={handleRoleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className="RoleSelector-select-container"
                >
                    <MenuItem value="">
                        <em>All Violation Severity Levels</em>
                    </MenuItem>
                    {violationSeverityArray.map((violation: string) => {
                        return(
                            <MenuItem value={violation}> {violation} </MenuItem>
                        )
                        })}
                </Select>
            </FormControl>
        </div>
    )
}
export default ViolationSeveritySelector
import { useEffect, useState } from "react";
import useUserAssignments from "../../Hooks/useUserAssignments";
import "./Dashboard.css"
import DashboardCard from "./DashboardCard/DashboardCard";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router-dom";
import AuditRangeStandards from "./AuditRangeStandards/AuditRangeStandards";
import SummaryStats from "./SummaryStats/SummaryStats";
import CircularProgress from '@mui/material/CircularProgress';

interface DashboardProps {
  userInfo: {
      id: string;
  };
  accessToken: string
}

const Dashboard: React.FC<DashboardProps> = ({ userInfo, accessToken }) => {
  const { getUserAssignments, userAssignments, userAssignmentsErrorMsg } = useUserAssignments(accessToken);
  const [cardIndex, setCardIndex] = useState(0)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (userInfo && accessToken) {
        setIsLoading(true);
        getUserAssignments(userInfo.id);
    }
}, [userInfo, accessToken, getUserAssignments]);

useEffect(() => {
    if (userAssignments || userAssignmentsErrorMsg) {
        setIsLoading(false);
    }
}, [userAssignments, userAssignmentsErrorMsg]);


  if (isLoading) {
    return (
      <div className="Dashboard-loading"> 
        <CircularProgress sx={{ color: "rgb(6, 26, 101)" }} size={20}/>
      </div>
    )
  }

  if (userAssignmentsErrorMsg) {
    return (
      <div>Error: {userAssignmentsErrorMsg}</div>
    )
  }

  if (!userAssignments || userAssignments.length === 0) {
    return (
      <button 
        className="Dashboard-add-assignment-link" 
        onClick={() => {navigate("/profile/user-assignments")}}
      > 
        <div className="Dashboard-link-text"> Go to Assignments </div>
        <KeyboardArrowRightIcon 
          className="Dashboard-button-container"
        />
      </button>

)
}

return(
  <div className="Dashboard">
    <div className="Dashboard-header">
        Dashboard
    </div>
    <div className="Dashboard-card-row"> 
      {userAssignments.length > 1 && (
        <div className="Dashboard-arrow-container"> 
          <KeyboardArrowLeftIcon 
            className="Dashboard-button-index-container"
            onClick={() => { 
              if (cardIndex !== 0) {setCardIndex(cardIndex - 1)} 
              else { setCardIndex(userAssignments.length -1) }}}
          />
        </div>
      )}
      <div className="Dashboard-cards">
        <DashboardCard userInfo={userInfo} assignment={userAssignments[cardIndex]}/>
      </div>
      <div className="Dashboard-summary-stats"> 
        <SummaryStats accessToken={accessToken} assignment={userAssignments[cardIndex]}/> 
      </div>
      {userAssignments.length > 1 && (
        <div className="Dashboard-arrow-container"> 
          <KeyboardArrowRightIcon 
            className="Dashboard-button-index-container"
            onClick={() => { 
              if (cardIndex !== userAssignments.length - 1) { setCardIndex(cardIndex + 1) }
              else {setCardIndex(0)}}}
          />
        </div>
      )}
    </div>
    <AuditRangeStandards accessToken={accessToken} assignment={userAssignments[cardIndex]} />
  </div>
)
}

export default Dashboard
import { useState, useCallback } from "react";
import UserAssignment from "../models/userAssignments.interface";

const useUserAssignments = (accessToken: string) => {
    const [userAssignments, setUserAssignments] = useState<UserAssignment[] | undefined>();
    const [userAssignmentsErrorMsg, setUserAssignmentsErrorMsg] = useState<string>("");

    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getUserAssignments = useCallback(async (userId: string) => {
        if (!accessToken) {
            setUserAssignments(undefined);
            setUserAssignmentsErrorMsg("Access token is not available.");
            return;
        }
    
        try {
            const response = await fetch(`${apiServer}/private/users/getUserAssignments/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            });
    
            if (response.ok) {
                const jsonData = await response.json() as UserAssignment[] | undefined;
                setUserAssignments(jsonData ?? []);
                setUserAssignmentsErrorMsg("");
            } else {
                setUserAssignments(undefined);
                setUserAssignmentsErrorMsg("Error fetching user assignments.");
            }
        } catch (error) {
            setUserAssignments(undefined);
            setUserAssignmentsErrorMsg("An error occurred while fetching user assignments.");
            console.error(error);
        }
    }, [accessToken, apiServer]);
    

    return { getUserAssignments, userAssignments, userAssignmentsErrorMsg };
}

export default useUserAssignments;

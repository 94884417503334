import { useState } from "react";
import Registry from "../../models/registry.interface";


export default (): [(ncrid: string) => void, Registry[], string]  => {

    const [result, setResult] = useState<Registry[]>([]);
    const [errorMsg, setErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getResult = async (ncrid: string) => {
        const data = await fetch(`${apiServer}/ncrid/${ncrid}`, {
            method: "GET"
        });
        const jsonData = await data.json() as Registry[];
        if (jsonData.length > 0) setResult(jsonData)
        else {setResult([]); setErrorMsg("Cannot find NCR ID");}
    }
    return [getResult, result, errorMsg]
}
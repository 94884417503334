import "./StandardAdditionalInformation.css"

function StandardAdditionalInformation(props: any) {

    const standard = props.standard

    return (
        <>
            <div className="AdditionalInformation-subject">
                Purpose: 
                <div className="AdditionalInformation-subject-content"> 
                    {standard.purpose.replace("... See Standard details..", "")}
                </div>
            </div>
            <div className="AdditionalInformation-subject">
                Effective Date of Standard: 
                <div className="AdditionalInformation-subject-content"> 
                    {standard.effective_date_of_standard}
                </div>
            </div>
            { standard.inactive_date_of_standard && (
                <div className="AdditionalInformation-subject">
                    Inactive Date of Standard: 
                    <div className="AdditionalInformation-subject-content"> 
                        {standard.inactive_date_of_standard}
                    </div>
            </div>
            )}
            <div className="AdditionalInformation-subject">
                Standard Details: 
                <div className="AdditionalInformation-subject-content"> 
                    <a href={standard.standard_url} target="_blank" > {standard.standard_url} </a>
                </div>
            </div>
        </>
    )
}
export default StandardAdditionalInformation
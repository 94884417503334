import UserAssignment from "../../models/userAssignments.interface";

export default (): [(assignmentData: UserAssignment, accessToken: string) => void]  => {

    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const updateUserAssignmentAudit = async (assignmentData: UserAssignment, accessToken: string) => {
        if (assignmentData) {
            const result = await fetch(`${apiServer}/private/users/updateUserAssignmentAudit`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(assignmentData).replace("[", "").replace("]", "")
        });
        console.log(JSON.stringify(assignmentData))
        console.log(assignmentData.id)
        console.log("Update User Assignment Roles")
        const resultJson = JSON.stringify(result)
        console.log(resultJson)
        }
    }
    return [updateUserAssignmentAudit]
}
import { useEffect, useState } from "react";
import GetRegistryByNCRID from "../../../../API/RegistryController/GetRegistryByNCRID";
import TextField from "../TextField";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion } from "framer-motion";
import AddAssignmentStep from "../../../../models/addAssignmentStep";

function SearchNCRID(props: any) {

    const [input, setInput] = useState("")
    const step: AddAssignmentStep = props.step
    const [getResult, result, errorMsg] = GetRegistryByNCRID();

    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    useEffect(() => {
        if (result.length > 0) { props.idResult(result) }
    }, [result])

        return(
            <motion.div 
                variants={item}
                initial="hidden"
                animate="visible"
                transition={{ velocity: 1 }}
                className="EnterID-container"
            >
                { (step.name === "searchNCRID") && (
                    <>
                        <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        <div className="EnterID-text"> 
                            Enter NCR ID:
                        </div>
                        <TextField 
                            value={input}
                            onChange={setInput}
                        />
                        <button 
                            className="EnterID-button"
                            onClick={() => {
                                if (result.length === 0) {getResult(input)}
                                if (result.length > 0) {getResult(input)}
                            }}
                        > 
                            <ArrowForwardIosIcon sx = {{  fontSize: "1.25em"  }}/>
                        </button>
                    </>
                )}
            </motion.div>
        )
}
export default SearchNCRID
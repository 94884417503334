import { useState } from "react";
import { json } from "stream/consumers";
import User from "../../models/user.interface";
//removed unused variable domain below - EJE July 12, 2021
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

export default (): [(userId: string | undefined, accessToken: string) => void, User | undefined, string]  => {

    const [userInfo, setUserInfo] = useState<User>();
    const [userInfoErrorMsg, setUserInfoErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getUserInfo = async (userId: string | undefined, accessToken: string) => {
        if (userId) {
            const data = await fetch(`${apiServer}/private/users/getUser/${userId}`, {
                method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });
        const jsonData = await data.json() as User[];
        //console.log(userInfoErrorMsg)
        if (jsonData[0]) setUserInfo(jsonData[0])
        else {setUserInfoErrorMsg("Cannot find User");}
        }
    }
    return [getUserInfo, userInfo, userInfoErrorMsg]
}
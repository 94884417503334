import { useEffect, useState } from "react"
import FamilySelector from "../../../../Selectors/FamilySelector/FamilySelector"
import RoleSelector from "../../../../Selectors/RoleSelector/RoleSelector"
import StandardStatusSelector from "../../../../Selectors/StandardStatusSelector/StandardStatusSelector"
import StandardStatus from "../../../../models/standard.status.interface"
import UserAssignment from "../../../../models/userAssignments.interface"
import "./FilterAuditStandardsBy.css"
import ViolationSeveritySelector from "../../../../Selectors/ViolationSeveritySelector/ViolationSeveritySelector"

function FilterAuditStandardsBy(props: any) {

    const assignment: UserAssignment = props.assignment
    const [family, setFamily] = useState("")
    const [status, setStatus] = useState<StandardStatus>({ name: "", url: "" })
    const [selectedRole, setSelectedRole] = useState("")
    const [violationSeverity, setViolationSeverity] = useState("")

    const updateSelectedRole = (selectedRole: string) => {
        setSelectedRole(selectedRole)
    }

    const updateStatus = (status: StandardStatus) => {
        setStatus( {name: status.name, url: status.url})
    }

    const updateFamily = (family: string) => {
        setFamily(family)
    }

    const updateViolationSeverity = (violation: string) => {
        setViolationSeverity(violation)
    }

    useEffect(() => {
        props.family(family);
        props.status(status.url);
        props.role(selectedRole);
        props.violationSeverity(violationSeverity)
    }, [family, status, selectedRole, violationSeverity])

    return(
        <div className="FilterAuditStandardsBy">
            <div> Filter By: </div>
            <FamilySelector family={updateFamily} />
            <StandardStatusSelector status={updateStatus} autoFillStatus={false}/>
            <RoleSelector roles={assignment.roles.replace(/[""]+/g, "").split(",")} selectedRole={updateSelectedRole}/>
            <ViolationSeveritySelector violationSeverity={updateViolationSeverity}/>
        </div>
    )
}
export default FilterAuditStandardsBy
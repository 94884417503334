import { useState, useCallback } from "react";
import UserAssignment from "../models/userAssignments.interface";

export const useGetUserAssignments = () => {
    const [userAssignments, setUserAssignments] = useState<UserAssignment[] | undefined>();
    const [userAssignmentsErrorMsg, setUserAssignmentsErrorMsg] = useState<string>("");

    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getUserAssignments = useCallback(async (userId: string, accessToken: string) => {
        if (!userId || !accessToken) {
            return;
        }
   
        try {
   
            const response = await fetch(`${apiServer}/private/users/getUserAssignments/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            });

            if (response.ok) {
                const jsonData = await response.json() as UserAssignment[] | undefined;
                console.log("getting assignments");
                setUserAssignments(jsonData ?? []);
                setUserAssignmentsErrorMsg("");
            } else {
                setUserAssignments(undefined);
                setUserAssignmentsErrorMsg("Error fetching user assignments.");
            }
        } catch (error) {
            setUserAssignments(undefined);
            setUserAssignmentsErrorMsg("An error occurred while fetching user assignments.");
            console.error(error);
        }
    }, [apiServer]);

    return { getUserAssignments, userAssignments, userAssignmentsErrorMsg };
}

import React from 'react'; 
import './EnterID.css'

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
    value: string;
    onChange: (val: string) => void;
    textarea?: boolean; 
}

const TextField = ({onChange, textarea = false, ...rest}: TextFieldProps) => {
    const InputElement = textarea ? 'textarea' : 'input'; 
    return(
        <InputElement
        className={'EnterID-input'}
        onChange={({ target : { value } }: InputChangeEvent) => onChange(value)}
        {...rest}
        />
    );
};

export default TextField; 
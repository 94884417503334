import { useState } from "react";
import Registry from "../../../../models/registry.interface";
import "../EnterID.css";
import UserAssignment from "../../../../models/userAssignments.interface";
import SearchNCRID from "../Steps/SearchNCRID";
import SelectEntityAndRegion from "../Steps/SelectEntityAndRegion";
import Company from "../../../../models/company.interface";
import SelectRoles from "../Steps/SelectRoles";
import SelectLastAudit from "../Steps/SelectLastAudit";
import SelectNextAudit from "../Steps/SelectNextAudit";
import ReviewAssignmentEntry from "../Steps/ReviewAssignmentEntry";

interface AddByNCRIDProps {
    newAssignment: (newAssignment: UserAssignment) => void;
    userId: string;
    handleCreateUserAssignment: (assignment: UserAssignment) => void;
    accessToken: string;
  }

function AddByNCRID({ userId = "", handleCreateUserAssignment }: AddByNCRIDProps) {

    const [idResult, setIDResult] = useState<Registry[]>([])
    const [selectedNcrid, setSelectedNcrid] = useState("")
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [selectedEntity, setSelectedEntity] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")
    const [assignment, setAssignment] = useState<UserAssignment>()
    const [step, setStep] = useState({name: "searchNCRID", number: 1})
    const [lastAudit, setLastAudit] = useState<string | null>()
    const [nextAudit, setNextAudit] = useState<string | null>()
    const [auditCadence, setAuditCadence] = useState<number | null>()

    let initSlideInFromRight = { opacity: 0, x: "100%" }
    let finalSlideIn = { opacity: 1, x: "0%" }
    let initSlideInFromLeft = { opacity: 0, x: "-100%" }

    const [initAnimation, setInitAnimation] = useState( initSlideInFromRight )
    const [finalAnimation, setFinalAnimation] = useState( finalSlideIn )

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const handleIDResult = (idResult: Registry[], ncrid: string) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        if (idResult.length > 0) { 
            setIDResult(idResult); 
            setStep({ name: "selectEntityAndRegion", number: (step.number +1) });
            setSelectedNcrid(ncrid);
        }
    }

    const handleUpdateCompanyDetails = (company: Company) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setSelectedEntity(company.entity); 
        setSelectedRegion(company.region);
        setSelectedNcrid(company.ncrid)
        setStep({ name: "selectRoles", number: (step.number +1) })
    }

    const handleUpdateSelectedRoles = (selectedRoles: string[]) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setSelectedRoles(selectedRoles);
        setStep({ name: "selectLastAudit",number: (step.number +1) })
    }

    const handleUpdateLastAudit = (audit: string | null) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        if (audit) {
            setLastAudit(audit);
            setStep({ name: "selectNextAudit", number: (step.number +1) }); 
        } else {
            setStep({ name: "reviewAssignmentEntry", number: (step.number +2) }); 
            setLastAudit(null);
            setNextAudit(null);
            setAuditCadence(null) 
        }
    }

    const handleUpdateNextAudit = (audit: string) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setNextAudit(audit);
        setStep({ name: "reviewAssignmentEntry", number: (step.number +1) });
        setAuditCadence(null) 
    }

    const handleUpdateAuditCadence = (cadence: number) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setAuditCadence(cadence);
        setStep({ name: "reviewAssignmentEntry", number: (step.number +1) });
        handleNextAuditCadenceConversion(cadence)
    }

    const handleNextAuditCadenceConversion = (cadence: number) => {
        if (lastAudit) {
            var lastAuditArray = lastAudit?.split("");
            lastAuditArray[3] = (parseInt(lastAuditArray[3]) + cadence).toString();
            var nextAuditConvert = lastAuditArray.join("");
            setNextAudit(nextAuditConvert);
        }
    }

    const saveAssignment = () => {
        if (step.name === "reviewAssignmentEntry") {
            return(
                <div className="Add-assignment-container"> 
                    <button className="Add-assignment-button-container" onClick={handleAssignmentChange} > Save </button> 
                </div>
            )
        }
    }

    const handleAssignmentChange = () => {
        const newAssignment: UserAssignment = {
          id: null,
          user_id: userId,
          entity: selectedEntity,
          region: selectedRegion,
          ncrid: selectedNcrid,
          roles: JSON.stringify(selectedRoles),
          last_audit: lastAudit ? lastAudit : "",
          next_audit: nextAudit ? nextAudit : "",
          audit_cadence: auditCadence ? auditCadence : 0
        };
    
        handleCreateUserAssignment(newAssignment);
    };

    const handleBackButton = () => {
        setInitAnimation( initSlideInFromLeft ); setFinalAnimation( finalSlideIn )
        if (step.name === "selectEntityAndRegion") { setStep({ name: "searchNCRID", number: (step.number -1) }) } 
        else if (step.name === "selectRoles") { setStep({ name: "selectEntityAndRegion", number: (step.number -1) }) }
        else if (step.name === "selectLastAudit") { setStep({ name: "selectRoles", number: (step.number -1) }) }
        else if (step.name === "selectNextAudit") { setStep({ name: "selectLastAudit", number: (step.number -1) }) }
        else if (step.name === "reviewAssignmentEntry" && !lastAudit) { setStep({ name: "selectLastAudit", number: (step.number -2) }); setAuditCadence(null) }
        else if (step.name === "reviewAssignmentEntry") { setStep({ name: "selectNextAudit", number: (step.number -1) }) }
    }

    const backButton = () => {
        if (step.name !== "searchNCRID") {
            return(
                <button
                    className="Add-assignment-button-container"
                    onClick={handleBackButton}
                >
                    Back
                </button>
            )
        }
    }

    return(
        <div className="EnterID">
            { step.name === "reviewAssignmentEntry" && (
            <div style={{ width: "100%"}}>
                <div className="Enter-ID-body"> 
                    <ReviewAssignmentEntry step={step} entity={selectedEntity} region={selectedRegion} ncrid={selectedNcrid} roles={selectedRoles} lastAudit={lastAudit} nextAudit={nextAudit} auditCadence={auditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                </div>
                <div className="Add-assignment-button-row"> 
                    {backButton()}
                    {saveAssignment()}
                </div>
            </div>
            )}
            { step.name !== "reviewAssignmentEntry" && (
                <>
                    <ReviewAssignmentEntry step={step} entity={selectedEntity} region={selectedRegion} ncrid={selectedNcrid} roles={selectedRoles} lastAudit={lastAudit} nextAudit={nextAudit} auditCadence={auditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                    <div className="Enter-ID-container-right">
                        <div className="Enter-ID-body"> 
                            <SearchNCRID step={step} idResult={handleIDResult} ncrid={handleIDResult} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                            <SelectEntityAndRegion step={step}  idResult={idResult} company={handleUpdateCompanyDetails} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                            <SelectRoles step={step}  idResult={idResult} ncrid={selectedNcrid} selectedRoles={handleUpdateSelectedRoles} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                            <SelectLastAudit step={step} lastAudit={handleUpdateLastAudit} initAnimation={initAnimation} finalAnimation={finalAnimation} skip={true}/>
                            <SelectNextAudit step={step} nextAudit={handleUpdateNextAudit} auditCadence={handleUpdateAuditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                        </div>
                        <div className="Add-assignment-button-row"> 
                            {backButton()}
                            {saveAssignment()}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
export default AddByNCRID
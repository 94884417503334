import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';

function AuditDateSelector(props: any) {
    const error = props.error
    var today = new Date()
    const todaysDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [date, setDate] = React.useState<Dayjs | null>(dayjs(todaysDate));

    useEffect(() => {
        if (date?.month()) {
            let month = date?.month() + 1
            props.audit(date?.year() + "-" + month + "-" + date?.date())
        }
    }, [date])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                value={date}
                onChange={(newValue) => setDate(newValue)}
                sx = {{ p: 2 }}
            />
        </LocalizationProvider>
    );

}
export default AuditDateSelector
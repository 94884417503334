import { useEffect, useState } from 'react'
import Families from '../../../Selectors/FamilySelector/families.json'
import Family from '../../../models/family.interface'
import "./SummaryStats.css"
import UserAssignment from '../../../models/userAssignments.interface'
import ApplicabilityStats from '../../../models/applicabilityStats'
import GetApplicabilityStats from '../../../API/DashboardController/GetApplicabilityStats'
import CircularProgress from '@mui/material/CircularProgress';

function ApplicabilityTable(props: any) {

    const [hoverFamily, setHoverFamily] = useState({ id: "", name: ""})
    const assignment: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const selectedRole = props.selectedRole
    const rolesArray = assignment.roles.replace(/[""]+/g, "").split(",")
    const [getApplicabilityStats, applicabilityStats, applicabilityStatsErrorMsg] = GetApplicabilityStats()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (assignment.last_audit && assignment.next_audit && selectedRole) {
            getApplicabilityStats(assignment.last_audit, assignment.next_audit, selectedRole.split(","), accessToken)
            setIsLoading(true)
        } else if (assignment.last_audit && assignment.next_audit) {
            getApplicabilityStats(assignment.last_audit, assignment.next_audit, rolesArray, accessToken)
            setIsLoading(true)
        }
    }, [assignment, props.selectedRole])

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false)
        }
    }, [applicabilityStats])

    const handleMouseOver = (name: string) => {
        Families.map((family: Family) => {
            if (name === family.id) {
                setHoverFamily({ id:family.id, name:family.name })
            }
        })
    }

    const handleMouseLeave = () => {
        setHoverFamily({ id:"", name:"" })
    }

    const familyNameHint = (name: string) => {
        if (hoverFamily.id === name) {
            return(
                <div className="SummaryStats-family-name-hint">
                    {hoverFamily.name}
                </div>
            )
        }
    }
    
    return(
        <div className="SummaryStats-applicability-table-container">
                <div className="SummaryStats-applicability-table-header">
                    <div className="SummaryStats-header-container">
                        Family
                    </div>
                    <div className="SummaryStats-header-container">
                        Standards
                    </div>
                    <div className="SummaryStats-header-container">
                        Requirements
                    </div>
                    <div className="SummaryStats-header-container">
                        Parts
                    </div>
                    <div className="SummaryStats-subheader-container">
                        VRFs:
                    </div>
                    <div className="SummaryStats-header-container">
                        Low
                    </div>
                    <div className="SummaryStats-header-container">
                        Medium
                    </div>
                    <div className="SummaryStats-header-container">
                        High
                    </div>
                </div>
                { !isLoading && applicabilityStats && assignment.last_audit && assignment.next_audit && (
                    <>
                        {applicabilityStats.map((stats: ApplicabilityStats) => {

                            if (stats.standardNumber > 0) {
                                return(
                                    <div className="SummaryStats-applicability-table">
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "10px" }} onMouseOver={ () => {handleMouseOver(stats.familyName)}} onMouseLeave={handleMouseLeave}>
                                            {stats.familyName} {familyNameHint(stats.familyName)}
                                        </div>
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "5px" }}>
                                            {stats.standardNumber}
                                        </div>
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "5px" }}>
                                            {stats.requirementNumber}
                                        </div>
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "5px" }}>
                                            {stats.partNumber}
                                        </div>
                                        <div className="SummaryStats-table-text">
                                            
                                        </div>
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "5px" }}>
                                            {stats.violationRiskFactor.lowViolation}
                                        </div>
                                        <div className="SummaryStats-table-text" style ={{ paddingLeft: "5px" }}>
                                            {stats.violationRiskFactor.mediumViolation}
                                        </div>
                                        <div className="SummaryStats-table-text" style = {{ color: (stats.violationRiskFactor.highViolation > 0) ? "red" : "black", fontWeight: (stats.violationRiskFactor.highViolation > 0) ? "bold" : "normal", paddingLeft: "5px"}}>
                                            {stats.violationRiskFactor.highViolation}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </>
                )}
                { isLoading && (
                    <div className="SummaryStats-loading"> 
                        <CircularProgress sx={{ color: "rgb(6, 26, 101)" }} size={20}/>
                    </div>
                )}
                { !isLoading && !assignment.last_audit && !assignment.next_audit && (
                    <div className="SummaryStats-nav-to-assignments"> Edit Assignment Details to View Stats </div>
                )}
             </div>
    )
}
export default ApplicabilityTable
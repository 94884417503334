import "./Profile.css"
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import useWindowSize from "../../Hooks/useWindowSize";
import { Link, Outlet, useLocation } from "react-router-dom";

function Profile(props: any) {

    const location = useLocation();
    const windowSize = useWindowSize();

    const profileNav = () => {

    return(
        <div 
            style = {{ width: (windowSize.width > 1500) ? "10vw" : "5vw"}}
            className="ProfileNav"
        >
            { (windowSize.width > 1500) && (
                <div className="ProfileNav-container"> 
                    <div className="ProfileNav-header">
                        Settings
                    </div>
                </div>
            )} 
            <div className="ProfileNav-container">
                <Link
                    to={""}
                    style = {{ width: (windowSize.width > 1500) ? "150px" : "fit-content", color: (location.pathname === "/profile") ? "black" : "rgb(77, 77, 77)"}}
                    className="ProfileNav-button"
                >
                    <PersonIcon sx = {{ fontSize: "1.4em", paddingRight: "4px" }} />
                    { (windowSize.width > 1500) && (
                        <> Profile </>
                    )}
                </Link>
                <Link
                    to={"user-assignments"}
                    style = {{ width: (windowSize.width > 1500) ? "150px" : "fit-content", color: (location.pathname === "/profile/user-assignments") ? "black" : "rgb(77, 77, 77)"}}
                    className="ProfileNav-button"
                >
                    <BusinessIcon sx = {{ fontSize: "1.4em", paddingRight: "4px" }} />
                    { (windowSize.width > 1500) && (
                        <> Assignments </>
                    )}
                </Link>
            </div>
        </div>
    )
  }

  return(
      <div className="Profile">
        <div className="Profile-container"> 
            {profileNav()}
            <div className="Profile-element" style ={{ width: (windowSize.width > 1500) ? "90vw" : "95vw"}}>
                <Outlet />
            </div>
        </div>
      </div>
  )
};

export default Profile;
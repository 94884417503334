import { useState } from "react";
import UserAssignment from "../../../../models/userAssignments.interface";
import "../EnterID.css"
import SelectRoles from "../Steps/SelectRoles";
import SelectLastAudit from "../Steps/SelectLastAudit";
import SelectNextAudit from "../Steps/SelectNextAudit";
import ReviewAssignmentEntry from "../Steps/ReviewAssignmentEntry";
import SearchEntity from "../Steps/SearchEntity";
import Company from "../../../../models/company.interface";

function AddByEntity(props: any) {

    const userId = props?.userId ?? "";
    const [selectedNcrid, setSelectedNcrid] = useState("")
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [selectedEntity, setSelectedEntity] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")
    const [assignment, setAssignment] = useState<UserAssignment>()
    const [step, setStep] = useState({name: "searchEntity", number: 1})
    const [lastAudit, setLastAudit] = useState<string | null>("")
    const [nextAudit, setNextAudit] = useState<string | null>("")
    const [auditCadence, setAuditCadence] = useState<number | null>()

    let initSlideInFromRight = { opacity: 0, x: "100%" }
    let finalSlideIn = { opacity: 1, x: "0%" }
    let initSlideInFromLeft = { opacity: 0, x: "-100%" }

    const [initAnimation, setInitAnimation] = useState( initSlideInFromRight )
    const [finalAnimation, setFinalAnimation] = useState( finalSlideIn )

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const handleUpdateEntitySearch = (company: Company) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setSelectedEntity(company.entity); 
        setSelectedRegion(company.region);
        setSelectedNcrid(company.ncrid);
        setStep({ name: "selectRoles", number: (step.number +1) })
    }

    const handleUpdateSelectedRoles = (selectedRoles: string[]) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setSelectedRoles(selectedRoles);
        setStep({ name: "selectLastAudit", number: (step.number +1) })
    }

    const handleUpdateLastAudit = (audit: string | null) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        if (audit) {
            setLastAudit(audit);
            setStep({ name: "selectNextAudit", number: (step.number +1) }); 
        } else {
            setStep({ name: "reviewAssignmentEntry", number: (step.number +2) }); 
            setLastAudit(null);
            setNextAudit(null);
            setAuditCadence(null) 
        }
    }

    const handleUpdateNextAudit = (audit: string) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setNextAudit(audit);
        setStep({ name: "reviewAssignmentEntry", number: (step.number +1) });
    }

    const handleUpdateAuditCadence = (cadence: number) => {
        setInitAnimation( initSlideInFromRight ); 
        setFinalAnimation( finalSlideIn );
        setAuditCadence(cadence);
        setStep({ name: "reviewAssignmentEntry", number: (step.number +1) });
        handleNextAuditCadenceConversion(cadence)
    }

    const handleNextAuditCadenceConversion = (cadence: number) => {
        if (lastAudit) {
            var lastAuditArray = lastAudit?.split("-");
            lastAuditArray[0] = (parseInt(lastAuditArray[0]) + cadence).toString();
            var nextAuditConvert = lastAuditArray.join("-");
            setNextAudit(nextAuditConvert);
        }
    }

    const saveAssignment = () => {
        if (step.name === "reviewAssignmentEntry") {
            return(
                <div className="Add-assignment-container"> 
                    <button className="Add-assignment-button-container" onClick={handleAssignmentChange} > Save </button> 
                </div>
            )
        }
    }

    const handleAssignmentChange = () => {
        const assignment: UserAssignment = {
            id: null,
            user_id: userId,
            entity: selectedEntity,
            region: selectedRegion,
            ncrid: selectedNcrid,
            roles: JSON.stringify(selectedRoles),
            last_audit: lastAudit!,
            next_audit: nextAudit!,
            audit_cadence: auditCadence!
        };
        
        // Pass assignment directly to parent
        props.newAssignment(assignment);
    }
    
    const handleBackButton = () => {
        setInitAnimation( initSlideInFromLeft ); setFinalAnimation( finalSlideIn )
        if (step.name === "selectRoles") { setStep({ name: "searchEntity", number: (step.number -1) }) }
        else if (step.name === "selectLastAudit") { setStep({ name: "selectRoles", number: (step.number -1) }) }
        else if (step.name === "selectNextAudit") { setStep({ name: "selectLastAudit", number: (step.number -1) }) }
        else if (step.name === "reviewAssignmentEntry" && !lastAudit) { setStep({ name: "selectLastAudit", number: (step.number -2) }); setAuditCadence(null) }
        else if (step.name === "reviewAssignmentEntry") { setStep({ name:"selectNextAudit", number: (step.number -1) }) }
    }

    const backButton = () => {
        if (step.name !== "searchEntity") {
            return(
                <button
                    className="Add-assignment-button-container"
                    onClick={handleBackButton}
                >
                    Back
                </button>
            )
        }
    }

    return(
        <div className="EnterID">
            { step.name === "reviewAssignmentEntry" && (
            <div style={{ width: "100%"}}>
                <div className="Enter-ID-body"> 
                    <ReviewAssignmentEntry step={step} entity={selectedEntity} region={selectedRegion} ncrid={selectedNcrid} roles={selectedRoles} lastAudit={lastAudit} nextAudit={nextAudit} auditCadence={auditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                </div>
                <div className="Add-assignment-button-row"> 
                    {backButton()}
                    {saveAssignment()}
                </div>
            </div>
            )}
            { step.name !== "reviewAssignmentEntry" && (
                <>
                    <ReviewAssignmentEntry step={step} entity={selectedEntity} region={selectedRegion} ncrid={selectedNcrid} roles={selectedRoles} lastAudit={lastAudit} nextAudit={nextAudit} auditCadence={auditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                    <div className="Enter-ID-container-right">
                        <div className="Enter-ID-body"> 
                            <SearchEntity step={step} company={handleUpdateEntitySearch} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                            <SelectRoles step={step} ncrid={selectedNcrid} selectedRoles={handleUpdateSelectedRoles} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                            <SelectLastAudit step={step} lastAudit={handleUpdateLastAudit} initAnimation={initAnimation} finalAnimation={finalAnimation} skip={true}/>
                            <SelectNextAudit step={step} nextAudit={handleUpdateNextAudit} auditCadence={handleUpdateAuditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                        </div>
                        <div className="Add-assignment-button-row"> 
                            {backButton()}
                            {saveAssignment()}
                        </div>
                    </div> 
                </>
            )}
        </div>
    )

}
export default AddByEntity
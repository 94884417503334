import UserAssignment from "../models/userAssignments.interface";

export const useUpdateUserAssignmentRoles = (): [(assignmentData: UserAssignment, accessToken: string) => Promise<UserAssignment | null>]  => {
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const updateUserAssignmentRoles = async (assignmentData: UserAssignment, accessToken: string) => {
        if (assignmentData) {
            const response = await fetch(`${apiServer}/private/users/updateUserAssignmentRoles`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(assignmentData).replace("[", "").replace("]", "")
            });

            if (response.ok) {
                console.log("Update User Assignment Roles");
                const result = await response.json();
                return result as UserAssignment;
            } else {
                console.error("Error updating user assignment:", response.status);
            }
        }
        return null;
    };

    return [updateUserAssignmentRoles];
};

import EditIcon from '@mui/icons-material/Edit';
import SelectLastAudit from '../../AddUserAssignment/Steps/SelectLastAudit';
import UserAssignment from '../../../../models/userAssignments.interface';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import "./EditUserAssignments.css"
import UpdateUserAssignmentAudit from '../../../../API/UserAssignmentsController/UpdateUserAssignmentAudit';
import SelectNextAudit from '../../AddUserAssignment/Steps/SelectNextAudit';
import { AnimatePresence, motion } from 'framer-motion';
import AddAssignmentStep from '../../../../models/addAssignmentStep';

function EditUserAssignmentAudit(props: any) {

    const [ editAuditPopUp, setEditAuditPopUp ] = useState(false)
    const [ step, setStep ] = useState<AddAssignmentStep>({ name: "selectLastAudit", number: null })
    const idResult: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const [lastAudit, setLastAudit] = useState<string | null>("")
    const [nextAudit, setNextAudit] = useState<string | null>("")
    const [auditCadence, setAuditCadence] = useState<number | null>()
    const [updateUserAssignmentAudit] = UpdateUserAssignmentAudit()
    const [ error, setError ] = useState("")

    const [initAnimation, setInitAnimation] = useState(  { opacity: 0, x: "0%" } )
    const [finalAnimation, setFinalAnimation] = useState( { opacity: 1, x: "0%" } )

    const assignment: UserAssignment = {
        id: idResult.id,
        user_id: idResult.user_id,
        entity: idResult.entity,
        region: idResult.region,
        ncrid: idResult.ncrid,
        roles: JSON.stringify(idResult.roles),
        last_audit: lastAudit,
        next_audit: nextAudit,
        audit_cadence: auditCadence!
    }

    const handleUpdateLastAudit = (audit: string) => {
        setLastAudit(audit);
        setStep({ name: "selectNextAudit", number: null })
    }

    const handleUpdateNextAudit = (audit: string) => {
        if (checkNextAudit(audit)) {
            setNextAudit(audit)
            setAuditCadence(null)
            setError("")
        } else {
            setError("Date must be after the Last Audit date")
        }
    }

    const checkNextAudit = (audit: string) => {
        if (audit && lastAudit) {
            var nextAuditArray = audit.split("-")
            var lastAuditArray = lastAudit?.split("-")

            if (nextAuditArray[0] > lastAuditArray[0]) {
                return true
            } else if (nextAuditArray[0] = lastAuditArray[0]) {
                if (nextAuditArray[1] > lastAuditArray[1]) {
                    return true
                } else if (nextAuditArray[1] = lastAuditArray[1]) {
                    if (nextAuditArray[2] > lastAuditArray[2]) {
                        return true
                    } else if (nextAuditArray[2] = lastAuditArray[2]) {
                        return false
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    const handleUpdateAuditCadence = (cadence: number) => {
        setAuditCadence(cadence);
        handleNextAuditCadenceConversion(cadence)
        setError("")
    }

    const handleNextAuditCadenceConversion = (cadence: number) => {
        if (lastAudit) {
            var lastAuditArray = lastAudit?.split("-");
            lastAuditArray[0] = (parseInt(lastAuditArray[0]) + cadence).toString();
            var nextAuditConvert = lastAuditArray.join("-");
            setNextAudit(nextAuditConvert);
        }
    }

    useEffect(() => {
        if (nextAudit && editAuditPopUp) {
            updateUserAssignmentAudit(assignment, accessToken)
            props.refresh(true)
            setEditAuditPopUp(false);
            setStep({ name: "selectLastAudit", number: null });
        }
    }, [nextAudit])

    const handleEditAuditPopup = () => {

        return(
            <AnimatePresence>
                { editAuditPopUp && (
                    <motion.div 
                        className="EditUserAssignment-pop-up"
                        initial={{ opacity: 0, scale: 0.75, }}
                        animate={{ opacity: 1, scale: 1,
                            transition: {
                                ease: "easeOut",
                                duration: 0.15,
                            }, }}
                        exit={{
                            opacity: 0,
                            scale: 0.75,
                            transition: {
                                ease: "easeIn",
                                duration: 0.15,
                            },
                        }}  
                    >
                        <SelectLastAudit step={step}  idResult={idResult} lastAudit={handleUpdateLastAudit} initAnimation={initAnimation} finalAnimation={finalAnimation} skip={false} />
                        <SelectNextAudit error={error} step={step}  idResult={idResult} nextAudit={handleUpdateNextAudit} auditCadence={handleUpdateAuditCadence} initAnimation={initAnimation} finalAnimation={finalAnimation}/>
                        <CloseIcon 
                            className="EditUserAssignment-pop-up-close-icon" 
                            onClick = {() => { setEditAuditPopUp(!editAuditPopUp); setStep({ name: "selectLastAudit", number: null }); setError("")} }
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        )
    }

    return(
        <>
            <EditIcon 
                className="EditUserAssignments-button-edit-container" 
                onClick = { () => { setEditAuditPopUp(!editAuditPopUp) } }
            />
            {handleEditAuditPopup()}
       </>
    )
}
export default EditUserAssignmentAudit
import { Checkbox } from "@mui/material"
import { useEffect, useState } from "react"
import "./MultipleRolesSelector.css"

function SelectRoleCheckBox(props: any) {

    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const roles = props.roles

    useEffect(() => {
        setSelectedRoles([])
    }, [props.result])

    const addRoleToSelectedRoles = (role: string) => {
        if (!selectedRoles.includes(role)) {
            let newRoles = selectedRoles
            newRoles.push(role)
            setSelectedRoles(newRoles)
        } else {
            let newRoles = selectedRoles
            selectedRoles.forEach((item, index) => {
                if (item === role) newRoles.splice(index, 1)
            })
            setSelectedRoles(newRoles)
        }
        props.selectedRoles(selectedRoles)
        console.log(selectedRoles)
    }

    return(
        <div className="SelectRole"> 
            {roles.map((role: string) => {
                return(
                    <div className="SelectRole-checkbox-row">
                        <Checkbox
                            sx={{ p: 0.25, '&.Mui-checked': { color: "rgb(56, 125, 215)"}}}
                            key={role + "select"}
                            onClick={() => {addRoleToSelectedRoles(role)}}
                        /> 
                        <div className="SelectRole-checkbox-text"> 
                            {role}
                        </div> 
                    </div>
                )
            })}
        </div>
    )
}
export default SelectRoleCheckBox
import { User, useAuth0 } from "@auth0/auth0-react";
import "./UserInfo.css"
import CompanyInfo from "./CompanyInfo/CompanyInfo";

function UserInfo(props: any) {

    const { isAuthenticated, user } = useAuth0();
    const userInfo = props.userInfo

    return(
        <div className="UserInfo">
            <div className="UserInfo-header-row"> 
                <div className="UserInfo-header-text"> Profile </div>
            </div>
            <div className="UserInfo-subheader-row"> 
                <div className="UserInfo-subheader-text"> Personal Details </div>
            </div>
            <div className="UserInfo-container"> 
                { isAuthenticated && (
                    <div className="UserInfo-user-picture-container"> 
                        <img className="UserInfo-user-picture" src={user?.picture}/>
                    </div>
                )}
                <div className="UserInfo-table-row">
                    <div className="UserInfo-table-header"> 
                        <div className="UserInfo-table-label"> Username </div>
                        <div className="UserInfo-table-label"> Email </div>
                    </div>
                    <div className="UserInfo-table"> 
                        <div className="UserInfo-table-response"> {userInfo?.user_name} </div>
                        <div className="UserInfo-table-response"> {userInfo?.email} </div>
                    </div>
                </div>
                <div className="UserInfo-table-row">
                    <div className="UserInfo-table-header"> 
                        <div className="UserInfo-table-label"> First Name </div>
                        <div className="UserInfo-table-label"> Last Name </div>
                    </div>
                    <div className="UserInfo-table"> 
                        <div className="UserInfo-table-response"> -- </div>
                        <div className="UserInfo-table-response"> -- </div>
                    </div>
                </div>
            </div>
            <CompanyInfo userInfo={userInfo}/>
        </div>
    )
}
export default UserInfo
import User from "../../models/user.interface";

const createNewUser = (): [(userData: User, accessToken: string) => Promise<void>] => {

    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const createUser = async (userData: User, accessToken: string): Promise<void> => {
        if (userData) {
            const result = await fetch(`${apiServer}/private/users/createUser`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData)
            });
            console.log("Creating New User")
            const resultJson = JSON.stringify(result)
            console.log(resultJson)
        }
    }

    return [createUser]
}

export default createNewUser;

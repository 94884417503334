import { useState } from "react";
import Requirement from "../../models/requirement.interface";

export default (): [(standardNumber: string, roles: string[]) => void, Requirement[], string]  => {

    const [requirements, setRequirements] = useState<Requirement[]>([]);
    const [requirementsErrorMsg, setRequirementsErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getRequirements = async (standardNumber: string, roles: string[]) => {
        const data = await fetch(`${apiServer}/requirements/${standardNumber}/${roles}`, {
            method: "GET"
        });
        const jsonData = await data.json() as Requirement[];
        console.log("getting requirements")
        if (jsonData.length > 0) setRequirements(jsonData)
        else {setRequirements([]); setRequirementsErrorMsg("Cannot find Requirements");}
    }
    return [getRequirements, requirements, requirementsErrorMsg]
}
import AddAssignmentStep from "../../../../models/addAssignmentStep"
import Registry from "../../../../models/registry.interface"
import { motion } from "framer-motion"

function SelectEntityAndRegion(props: any) {

    const idResult = props.idResult
    const step: AddAssignmentStep = props.step

    const initAnimation = props.initAnimation
    const finalAnimation = props.finalAnimation

    const item = {
        visible: finalAnimation,
        hidden: initAnimation,
    }

    const handleSelectedEntityAndRegion = (entity: string, region: string, ncrid: string) => {
        props.company({ entity: entity, region: region, ncrid: ncrid })
    }

    return(
        <>
            { (step.name === "selectEntityAndRegion") && (
                <motion.div 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        transition={{ velocity: 1 }}
                    >
                    <div className="EnterID-container">
                        <div className="Enter-ID-step-number"> Step {step.number}: </div>
                        <div className="EnterID-text"> Select Entity and Region: </div>
                    </div>
                    <div className="EnterID-column-container"> 
                        {idResult.map((registry: Registry) => {
                            return(
                                <button 
                                    key={registry.entity + registry.rcea } 
                                    className="item-container"
                                    onClick = {() => { handleSelectedEntityAndRegion( registry.entity, registry.rcea, registry.ncrid )}}
                                    // style = {{ background: itemCheck ? "rgb(225, 225, 225)" : "none" }}
                                > {registry.entity} {registry.rcea}
                                </button>
                            )
                        })}
                    </div>
                </motion.div>
            )}
        </>
    )
}
export default SelectEntityAndRegion
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import "./AuditDateCadenceSelector.css"

function AuditDateCadenceSelector(props: any) {

    const [cadence, setCadence] = useState("")
    const cadenceArray = [1, 3, 5, 6]

    const handleCadenceChange = (event: SelectChangeEvent) => {
        setCadence(event.target.value)
    }

    useEffect(() => {
        props.cadence(cadence)
    }, [cadence])

    return(
        <div className="AuditDateCadenceSelector">
            <FormControl sx={{ m: 0}}>
                <Select
                sx= {{ fontSize: "1em"}}
                value={cadence}
                onChange={handleCadenceChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className="AuditDateCadenceSelector-select-container"
                >
                    <MenuItem value="">
                        <em>Select Cadence</em>
                    </MenuItem>
                    {cadenceArray.map((year: number) => {
                        return(
                            <MenuItem value={year}> {year} year(s) </MenuItem>
                        )
                        })}
                </Select>
            </FormControl>
        </div>
    )
}
export default AuditDateCadenceSelector
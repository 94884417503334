import React, { useEffect } from "react";
import UserAssignment from "../../../models/userAssignments.interface";
import "./UserAssignments.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetUserAssignments } from "../../../Hooks/useGetUserAssignments";
import useDeleteUserAssignment from "../../../Hooks/useDeleteUserAssignment";
import { useCreateUserAssignment } from "../../../Hooks/useCreateUserAssignment";
import { useUpdateUserAssignmentRoles } from "../../../Hooks/useUpdateUserAssignmentRoles";
import AddUserAssignments from "../AddUserAssignment/AddUserAssignments";
import EditUserAssignmentRoles from "./EditUserAssignments/EditUserAssignmentRoles";
import EditUserAssignmentAudit from "./EditUserAssignments/EditUserAssignmentAudit";
import User from "../../../models/user.interface";


interface UserAssignmentsProps {
    userInfo: User;
    accessToken: string;
}

const UserAssignments: React.FC<UserAssignmentsProps> = ({ userInfo, accessToken }) => {
    const { getUserAssignments, userAssignments, userAssignmentsErrorMsg } = useGetUserAssignments();
    const { deleteUserAssignment } = useDeleteUserAssignment();
    const [ createNewUserAssignment ] = useCreateUserAssignment();
    const [ updateUserAssignmentRoles ] = useUpdateUserAssignmentRoles();


    useEffect(() => {
        getUserAssignments(userInfo.id, accessToken); 
    }, [userInfo.id, accessToken, getUserAssignments]);

    const handleAssignmentDelete = async (id: number | null) => {
        if (id) {
            await deleteUserAssignment(id, accessToken);
            if(userAssignments) {
                getUserAssignments(userInfo.id, accessToken);  // Refresh the assignments
            }
        }
    };

    const handleCreateUserAssignment = async (newAssignmentData: UserAssignment) => {
        const newAssignment = await createNewUserAssignment(newAssignmentData, accessToken);
        if (newAssignment) {
            getUserAssignments(userInfo.id, accessToken);  // Refresh the assignments
        }
    };

    const handleUpdateUserAssignment = async (updatedAssignmentData: UserAssignment) => {
        const updatedAssignment = await updateUserAssignmentRoles(updatedAssignmentData, accessToken);
        if (updatedAssignment) {
            getUserAssignments(userInfo.id, accessToken);  // Refresh the assignments
        }
    };

    return(
        <div className="UserAssignment-background">
            <AddUserAssignments userInfo={userInfo} accessToken={accessToken} handleCreateUserAssignment={handleCreateUserAssignment} />
            {userAssignments && ( 
                <>
                    <div className="UserAssignment-table-header">
                        <div className="UserAssignment-container-label"> Entity </div>
                        <div className="UserAssignment-container-label"> Region </div>
                        <div className="UserAssignment-container-label"> NCRID </div>
                        <div className="UserAssignment-container-label"> Roles </div>
                        <div className="UserAssignment-container-label"> Upcoming Audit </div>
                        <></>
                    </div>
                    {userAssignments.map((assignment: UserAssignment) => {
                        return(
                            <>
                                { userAssignments && (
                                    <div className="UserAssignment-table">
                                        <div className="UserAssignment-container-response"> {assignment.entity} </div>
                                        <div className="UserAssignment-container-response"> {assignment.region} </div>
                                        <div className="UserAssignment-container-response"> {assignment.ncrid} </div>
                                        <div className="UserAssignment-container-response"> 
                                            <EditUserAssignmentRoles assignment={assignment} accessToken={accessToken} handleUpdateUserAssignment={handleUpdateUserAssignment}/>
                                            {assignment.roles.replace(/[,]+/g, ", ").replace(/['"{}]+/g, "")} 
                                        </div>
                                        <div className="UserAssignment-container-response"> 
                                            <EditUserAssignmentAudit assignment={assignment} accessToken={accessToken} />
                                            {assignment.last_audit?.replace(/[-]+/g, "/")} - {assignment.next_audit?.replace(/[-]+/g, "/")}
                                        </div>
                                        <button 
                                            className="UserAssignment-button-container"
                                            onClick={ () => { handleAssignmentDelete(assignment.id) }}
                                            > <DeleteIcon sx = {{ color: "darkRed" }} />
                                        </button>
                                    </div>
                                )}
                            </>
                        )
                    })}
                </>
            )}
            { !userAssignments && (
                <div className="UserAssignment-no-response"> {userAssignmentsErrorMsg} </div>
            )}
        </div>
    )
}
export default UserAssignments;

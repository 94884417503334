import { Link } from "react-router-dom"
import UserAssignment from "../../../models/userAssignments.interface"
import "./DashboardCard.css"
import EditIcon from '@mui/icons-material/Edit';
import AuditCountDown from "../AuditCountDown/AuditCountDown";

function DashboardCard(props: any) {

    const assignment: UserAssignment = props.assignment
    const userInfo = props.userInfo

    const auditCountdown = (nextAudit: string | null) => {

        if (nextAudit) {
            const date = new Date(nextAudit)

            return(
            <div className="DashboardCard-row-container" style={{ marginTop: "0px"}}> 
                <div className="DashboardCard-header-container"> 
                    Audit Countdown:
                    <AuditCountDown targetDate={date} />
                </div>
            </div>
            )
        }
    }
    
    return(
        <div className="DashboardCard-container">
            <div className="DashboardCard-row-container"> 
                <div className="DashboardCard-header-container" style={{ paddingTop: "10px", fontWeight: "550"}}> 
                    {assignment.entity} {assignment.region}
                </div>
            </div>
            <div className="DashboardCard-row-container" style= {{ marginTop: "5px"}}> 
                <div className="DashboardCard-subheader-container">
                    {assignment.ncrid}
                </div>
            </div>
            <div className="DashboardCard-row-container"> 
                <div className="DashboardCard-header-container"> 
                    {assignment.roles.replace(/[,]+/g, ", ").replace(/['"{}]+/g, "")}
                </div>
            </div>
            <div className="DashboardCard-row-container"> 
                <div className="DashboardCard-header-container"> 
                    Upcoming Audit: {assignment.last_audit?.replace(/[-]+/g, "/")} - {assignment.next_audit?.replace(/[-]+/g, "/")}
                </div>
            </div>
            {/* {auditCountdown(assignment.next_audit)} */}
            <div className="DashboardCard-row-container" style = {{ justifyContent: "flex-end", marginBottom: "15px"}}> 
                <Link to="/profile/user-assignments" className="DashboardCard-edit-link"> 
                    <EditIcon className="DashboardCard-button-edit-container " sx = {{ fontSize: "1.25em", p: 0 }} />
                    <div className="DashboardCard-edit-link-text"> Assignment Details </div>
                </Link>
            </div>
        </div>
    )
}
export default DashboardCard
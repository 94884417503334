import "./AddUserAssignments.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from "react";
import User from "../../../models/user.interface";
import UserAssignment from "../../../models/userAssignments.interface";
import AddByNCRID from "./AddByNCRID/AddByNCRID";
import AddByEntity from "./AddByEntity/AddByEntity";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { motion } from "framer-motion";

interface AddUserAssignmentsProps {
  userInfo: User | undefined;
  accessToken: string;
  handleCreateUserAssignment: (newAssignmentData: UserAssignment) => Promise<void>;
}

function AddUserAssignments(props: AddUserAssignmentsProps) {
  const { userInfo, accessToken, handleCreateUserAssignment } = props;
  const [addIcon, setAddIcon] = useState(false);
  const [addItemSetting, setAddItemSetting] = useState(true);
  const [assignment, setAssignment] = useState<UserAssignment | undefined>();
  const userId = userInfo?.id ?? "";
  

  const addItemBy = () => {
    if (addItemSetting && addIcon) {
      return (
        <AddByNCRID
          newAssignment={updateNewAssignment}
          userId={userId}
          accessToken={accessToken}
          handleCreateUserAssignment={handleCreateUserAssignment}
        />
      );
    } else {
      return (
        <AddByEntity
          newAssignment={updateNewAssignment}
          userId={userId}
          accessToken={accessToken}
          handleCreateUserAssignment={handleCreateUserAssignment}
        />
      );
    }
  };

  const updateNewAssignment = (newAssignment: UserAssignment) => {
    setAssignment(newAssignment);
  };

  useEffect(() => {
    const createAssignment = async () => {
      if (assignment) {
        await handleCreateUserAssignment(assignment);
        setAddIcon(false);
      }
    };

    createAssignment();
  }, [assignment, handleCreateUserAssignment]);

  const variants = {
    open: { opacity: 1, height: "400px" },
    closed: { opacity: 0, height: "0px" },
  };

  const addItem = () => {
    return (
      <motion.div
        layout
        animate={addIcon ? "open" : "closed"}
        transition={{ type: "spring", stiffness: 200, damping: 70 }}
        variants={variants}
        className="AddUserAssignments-add-item"
      >
        {addIcon && (
          <>
            <div className="AddUserAssignments-add-item-search-by-container">
              <div className="AddUserAssignments-add-item-container">
                Search By:
              </div>
              <div className="AddUserAssignments-button-row">
                <button
                  className="AddUserAssignments-add-item-button"
                  style={{
                    border: "none",
                    borderBottom: addItemSetting
                      ? "2px solid rgb(6, 26, 101)"
                      : "none",
                  }}
                  onClick={() => {
                    setAddItemSetting(true);
                  }}
                >
                  NCRID
                </button>
                <button
                  className="AddUserAssignments-add-item-button"
                  style={{
                    border: "none",
                    borderBottom: addItemSetting
                      ? "none"
                      : "2px solid rgb(6, 26, 101)",
                  }}
                  onClick={() => {
                    setAddItemSetting(false);
                  }}
                >
                  Entity
                </button>
              </div>
            </div>
            {addItemBy()}
          </>
        )}
      </motion.div>
    );
  };

  return (
    <>
      <div className="AddUserAssignments">
        <div className="AddUserAssignments-header-text"> Assignments </div>
        {!addIcon && (
          <AddCircleIcon
            className="AddUserAssignments-add-icon"
            onClick={() => {
              setAddIcon(!addIcon);
            }}
            sx={{ fontSize: "1.65em", p: 1 }}
          />
        )}
        {addIcon && (
          <RemoveCircleIcon
            className="AddUserAssignments-add-icon"
            onClick={() => {
              setAddIcon(!addIcon);
            }}
            sx={{ fontSize: "1.65em", p: 1 }}
          />
        )}
      </div>
      {addItem()}
    </>
  );
}

export default AddUserAssignments;

import { useState } from "react";
import Part from "../../models/part.interface";

export default (): [(standardNumber: string, requirementNumber: string, roles: string[]) => void, Part[], string]  => {

    const [parts, setParts] = useState<Part[]>([]);
    const [partsErrorMsg, setPartsErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getParts = async (standardNumber: string, requirementNumber: string, roles: string[]) => {
        const data = await fetch(`${apiServer}/parts/${standardNumber}/${requirementNumber}/${roles}`, {
            method: "GET"
        });
        const jsonData = await data.json() as Part[];
        console.log("getting parts")
        if (jsonData.length > 0) setParts(jsonData)
        else {setParts([]); setPartsErrorMsg("Cannot find Mandatory Standards");}
    }
    return [getParts, parts, partsErrorMsg]
}
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import StandardStatus from "../../models/standard.status.interface"
import "./StandardStatusSelector.css"

function StandardStatusSelector(props: any) {

    const [status, setStatus] = useState<StandardStatus>({ name: "", url: "" })
    const autoFillStatus = props.autoFillStatus

    useEffect(() => {
        if (autoFillStatus) {
            setStatus({ name: "Mandatory Subject to Enforcement", url: "Mandatory%20Subject%20to%20Enforcement" })
        }
    }, [])

    const handleStatusChange = (event: SelectChangeEvent) => {
        setStatus( {name: event.target.value, url: event.target.value.replace(/[" "]+/g, "%20")})
    }

    useEffect(() => {
        props.status(status)
    }, [status])

    return(
        <div className="StatusSelector">
            <FormControl sx={{ m: 0}}>
                <Select
                sx= {{ fontSize: "1em", borderRadius: "50px", padding: "5px"}}
                value={status.name!}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className="StatusSelector-select-container"
                >
                    { !autoFillStatus && (
                        <MenuItem value={""}>
                            <em>All Statuses</em>
                        </MenuItem>
                    )}
                    <MenuItem value={"Mandatory Subject to Enforcement"}> Mandatory Subject to Enforcement </MenuItem>
                    <MenuItem value={"Subject to Future Enforcement"}> Subject to Future Enforcement </MenuItem>
                    <MenuItem value={"Filed and Pending Regulatory Approval"}> Filed and Pending Regulatory Approval </MenuItem>
                    <MenuItem value={"Pending Regulatory Filing"}> Pending Regulatory Filing </MenuItem>
                    <MenuItem value={"Inactive"}> Inactive </MenuItem>
                    <MenuItem value={"Pending Inactive"}> Pending Inactive </MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}
export default StandardStatusSelector
import { useState } from "react";


export default (): [(ncrid: string) => void, Array<string>, string] => {
    
    const [roles, setRoles] = useState<Array<string>>([]);
    const [rolesErrorMsg, setErrorMsg] = useState("")
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getRoles = async (ncrid: string) => {
        const data = await fetch(`${apiServer}/${ncrid}/roles`, {
            method: "GET"
        });
        const jsonData = await data.json();
        if (jsonData.length > 0) setRoles(jsonData)
        else {setRoles([]); setErrorMsg("Cannot find Roles");}
    }
    return [getRoles, roles, rolesErrorMsg]
}
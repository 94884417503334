import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import "./Logout.css"

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <button className="Logout-button" onClick={ handleLogout }>
      Log Out
    </button>
  );
};

export default LogoutButton;
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import Families from './families.json'
import "./FamilySelector.css"
import Family from '../../models/family.interface'

function FamiliesList(props: any) {

    const [selectedFamily, setSelectedFamily] = useState("")

    const handleFamilyChange = (event: SelectChangeEvent) => {
        setSelectedFamily(event.target.value)
    }

    useEffect(() => {
        props.family(selectedFamily)
    }, [selectedFamily])

    return(
        <div className="FamilySelector">
            <FormControl sx={{ m: 0}}>
                <Select
                sx= {{ fontSize: "1em", borderRadius: "50px", padding: "5px"}}
                value={selectedFamily}
                onChange={handleFamilyChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className="FamilySelector-select-container"
                >
                    <MenuItem value="">
                        <em>All Families</em>
                    </MenuItem>
                    {Families.map((family: Family) => {
                        return(
                            <MenuItem value={family.id}> ({family.id}) {family.name} </MenuItem>
                        )
                        })}
                </Select>
            </FormControl>
        </div>
    )
}
export default FamiliesList
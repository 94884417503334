import { useState } from "react";
import Standard from "../../models/standard.interface";


export default (): [(lastAudit: string, nextAudit: string, roles: string[], accessToken: string, family: string, status: string, selectedRole: string, violationSeverity: string) => void, Standard[] | null, string]  => {
 
    const [standardsWithinAuditRange, setStandardsWithinAuditRange] = useState<Standard[] | null>(null);
    const [standardsWithinAuditRangeErrorMsg, setStandardsWithinAuditRangeErrorMsg] = useState("");
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const getStandardsWithinAuditRange = async (lastAudit: string, nextAudit: string, roles: string[], accessToken: string, family: string, status: string, selectedRole: string, violationSeverity: string) => {
        const data = await fetch(`${apiServer}/private/dashboard/getStandardsWithinAuditDateRange/${lastAudit}/${nextAudit}/${roles}?family=${family}&status=${status}&selectedRole=${selectedRole}&violationSeverity=${violationSeverity}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        
        const jsonData = await data.json() as Standard[];
        console.log("getting standards within audit date range")
        if (jsonData.length > 0) setStandardsWithinAuditRange(jsonData)
        else {setStandardsWithinAuditRange(null); setStandardsWithinAuditRangeErrorMsg("Cannot find Standards");}
    }
    return [getStandardsWithinAuditRange, standardsWithinAuditRange, standardsWithinAuditRangeErrorMsg]
}
import { useCallback } from "react";

const useDeleteUserAssignment = () => {
    const apiServer = process.env.REACT_APP_API_SERVER_URL;

    const deleteUserAssignment = useCallback(async (id: number, accessToken: string): Promise<void> => {
        if (id) {
            try {
                const response = await fetch(`${apiServer}/private/users/deleteUserAssignment/${id}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    },
                });

                if (response.ok) {
                    console.log("Deleting User Assignment");
                } else {
                    console.error("Error deleting user assignment:", response.status);
                }
            } catch (error) {
                console.error("An error occurred while deleting user assignment:", error);
            }
        }
    }, [apiServer]);

    return { deleteUserAssignment };
}

export default useDeleteUserAssignment;
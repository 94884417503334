import { useEffect, useState } from "react"
import UserAssignment from "../../../models/userAssignments.interface"
import ApplicabilityTable from "./ApplicabilityTable"
import DeltaStandards from "./DeltaStandards"
import "./SummaryStats.css"
import RoleSelector from "../../../Selectors/RoleSelector/RoleSelector"

function SummaryStats(props: any) {

    const assignment: UserAssignment = props.assignment
    const accessToken = props.accessToken
    const [selectedRole, setSelectedRole] = useState<string>()

    const updateSelectedRole = (selectedRole: string) => {
        if (selectedRole !== "") {
            setSelectedRole(selectedRole)
        } else {
            setSelectedRole(undefined)
        }
    }

    return(
        <div className="SummaryStats-container">
            <div className="SummaryStats-row-container">  
                <div className="SummaryStats-row-container"  style={{ justifyContent: "flex-start", padding: "0px"}}> 
                    <div className="SummaryStats-header-container" style={{ fontWeight: "550" }}> 
                        Summary Stats:
                    </div>
                    <RoleSelector roles={assignment.roles.replace(/[""]+/g, "").split(",")} selectedRole={updateSelectedRole} assignment={assignment}/>
                </div>
                <div className="SummaryStats-audit-range"> {assignment.last_audit?.replace(/[-]+/g, "/")} - {assignment.next_audit?.replace(/[-]+/g, "/")} </div>
            </div>
            <ApplicabilityTable assignment={assignment} accessToken={accessToken} selectedRole={selectedRole}/>
            <DeltaStandards assignment={assignment} accessToken={accessToken} selectedRole={selectedRole}/>
        </div>
    )
}
export default SummaryStats
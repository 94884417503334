import "./CompanyInfo.css"

function CompanyInfo(props: any) {

    const userInfo = props.userInfo

    return(
        <div className="CompanyInfo">
            <div className="CompanyInfo-subheader-row"> 
                <div className="CompanyInfo-subheader-text"> Company Details </div>
            </div>
            <div className="CompanyInfo-container"> 
                <div className="CompanyInfo-table-row">
                    <div className="CompanyInfo-table-header"> 
                        <div className="CompanyInfo-table-label"> Company Name </div>
                        <div className="CompanyInfo-table-label"> Company Admin </div>
                    </div>
                    <div className="CompanyInfo-table"> 
                        <div className="CompanyInfo-table-response"> -- </div>
                        <div className="CompanyInfo-table-response"> -- </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanyInfo